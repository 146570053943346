<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div
      v-show="isShowBrandDetail"
      class="analysis-wrap-tips"
    >
      <div
        v-if="lookNumber > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
        </div>
        <div class="btn">
          <span @click="lookBrandDetail()">查看拓展需求</span>
        </div>
      </div>
      <div
        v-else-if="interestsData.totallookbrandrightnum > 0"
        class="look-detail-wrap"
      >
        <div class="text">
          <span class="icon-small-tip"></span>
          您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
        </div>
      </div>
      <div
        v-else
        class="noData-look-brand"
      >
        <img
          src="@/assets/images/public/not-plandata.png"
          alt=""
        >
        <p>抱歉，您无查看权限。</p>
      </div>
    </div>
    <div
      v-show="isShowBrandContent"
      class="demand"
    >
      <div
        v-if="cityList.length > 0 || details.propertyContent !== '' || trimsStr !== '' "
        class="demand-content"
      >
        <div
          class="region"
          v-if="cityList.length>0"
        >
          <div class="region-head">计划拓展区域</div>
          <div class="region-list">
            <ul>
              <li
                :class="{active:currentTab === index}"
                :key="index"
                :index="index"
                v-for="(item, index) in provinceList"
                @click="toRegion(item.regionId, $event ,index)"
              >{{item.regionName}}</li>
            </ul>
          </div>
          <div class="city-list">
            <ul>
              <li
                :key="index"
                :index="index"
                v-for="(item, index) in cityList"
              >
                <span>{{item.provinceName}}:</span> {{item.cityNameList.replace(/,/g,' &nbsp;&nbsp;')}}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="property"
          v-if="details.propertyContent"
        >
          <div class="property-head">物业条件要求</div>
          <div
            class="property-main"
            v-html="details.propertyContent"
          ></div>
        </div>
        <div
          class="property"
          v-if="trimsStr !== ''"
        >
          <div class="property-head">其他拓展要求</div>
          <div
            class="property-main"
            v-html="details.otherOpenShopNeed"
          ></div>
        </div>
      </div>
      <div
        class="noData"
        v-if="isShowContent"
      >
        <p class="no-list-data"></p>
        <p>暂无拓展需求</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      loading: true,
      currentTab: 0,
      trimsStr: '',
      isShowBrandDetail: false,
      currentRid: '',
      provinceList: [],
      cityList: [],
      isShowContent: false,
      isShowBrandContent: false
    }
  },
  props: {
    details: {
      type: Object
    },
    lookNumber: {
      type: Number
    },
    interestsData: {
      type: Object
    }
  },
  created () {
    this.deleteHtmlTag()
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.axios.post(api.getBrandDetailIsShow,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        }
      )
        .then((res) => {
          this.isShowBrandDetail = res.data.data
          if (res.data.data) {
            this.isShowBrandContent = true
            this.isShowBrandDetail = false
            this.init()
          } else {
            this.isShowBrandContent = false
            this.isShowBrandDetail = true
          }
        })
      this.loading = false
    })
  },
  methods: {
    init () {
      // 获取区域
      this.axios.post(api.getTuozhanRegion,
        {
          brand_id: this.$route.query.id,
          gbid: parseInt(this.$route.query.gbid)
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            this.currentRid = res.data.data[0].regionId
            this.getCityList(this.currentRid)
          }
          this.provinceList = res.data.data
          this.loading = false
        })
    },
    lookBrandDetail () {
      this.axios.post(api.getBrandLookRightDeduction,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        }
      )
        .then((res) => {
          if (res.data.data === 1) {
            this.isShowBrandDetail = false
            this.isShowBrandContent = true
            this.init()
            this.$emit('updateMarker', true)
          }
        })
    },
    toRegion (rid, event, index) {
      // this.$emit('regionId', rid)
      this.currentTab = index
      this.regionId = rid
      this.getCityList(rid)
    },
    deleteHtmlTag () {
      this.trimsStr = this.details.otherOpenShopNeed.replace(/<[^>]+>|&[^>]+;/g, '').trim() // 去掉所有的html标签和&nbsp;之类的特殊符合
    },
    getCityList: function (rid) {
      // 获取区域城市
      this.axios.post(api.getBrandTuozhanProvinceCity, { brand_id: parseInt(this.$route.query.id), region_id: parseInt(rid), gbid: parseInt(this.$route.query.gbid) })
        .then((res) => {
          this.cityList = res.data.data
          if (this.cityList.length === 0 && this.trimsStr === '' && this.details.propertyContent === '') {
            this.isShowContent = true
          } else {
            this.isShowContent = false
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.demand
  width 1280px
  margin 0px auto
  padding 30px 0 0 0
  background-color #272930
  .demand-head
    border-left 2px solid #FFA134
    padding-left 10px
    margin-left 20px
    color #fff
  .demand-content
    padding 0px 20px 30px 20px
    .region
      .region-head
        width 100%
        color #e5e5e5
        font-size 16px
        border-left 2px solid #FFA134
        padding-left 10px
      .region-list
        margin-top 35px
        height 50px
        padding-left 14px
        li
          display inline-block
          width 82px
          height 30px
          text-align center
          line-height 30px
          border 1px solid #979797
          color #979797
          margin-right 15px
          cursor pointer
          font-size 14px
        .active
          border 1px solid #ffa134
          background-color #ffa134
          color #fff
        li:hover
          border 1px solid #ffa134
          background-color #ffa134
          color #fff
      .city-list
        li
          line-height 40px
          color #fff
          font-size 14px
          span
            display inline-block
            width 60px
            margin-right 10px
            color #ccc
            text-align left
            margin-left 15px
    .property
      margin-top 20px
      .property-head
        width 100%
        border-left 2px solid #ffa134
        padding-left 10px
        color #e5e5e5
        font-size 16px
      i
        font-style normal
      .property-main
        margin-top 10px
        line-height 30px
        color #ccc
        font-size 14px
        padding-left 14px
    .other
      margin-top 20px
      .other-head
        width 100%
        border-bottom 2px solid #454a54
        color #e5e5e5
        font-size 16px
        line-height 42px
        background-color #323a48
        padding-left 10px
      .other-content
        margin-top 20px
        line-height 36px
        color #fff
        font-size 14px
.need
  margin-bottom 20px
  font-weight 100
  span
    display inline-block
    width 300px
    color #fff
    margin-top 15px
    font-size 14px
    margin-left 12px
    font-weight 100
.noData
  padding-left 20px
  margin-top 30px
  color #fff
  padding-bottom 40px
  text-align center
  p
    margin-top 20px
    margin-right 20px
    font-size 14px
  .no-list-data
    width 136px
    height 98px
    background-size 100%
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
.look-detail-wrap
  padding-top 120px
  .text
    width 530px
    height 38px
    line-height 38px
    font-size 14px
    margin auto
    background-color rgba(0, 0, 0, 0.1)
    color #fff
    i
      color #ffa134
    .icon-small-tip:before
      color #fff
      vertical-align middle
  .btn
    margin-top 35px
    span
      display inline-block
      width 164px
      height 28px
      background-color #ffa134
      color #fff
      border-radius 3px
      font-size 14px
      line-height 28px
      cursor pointer
.noData-look-brand
  img
    padding-top 120px
  p
    color #fff
    font-size 12px
    font-weight 500
    margin-top 30px
</style>
