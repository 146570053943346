<template>
  <div
    class="brand-news"
    v-loading="loading"
  >
    <news-item
      v-for="item in newsList"
      :key="item.id"
      :detail='item'
      title="品牌详情"
    ></news-item>
    <div
      class="noData"
      v-if="newsList.length===0 && loading===false"
    >
      <p class="no-list-data"></p>
      <p class="no-news-text">暂无品牌资讯</p>
    </div>
    <div
      class="w-page-sty"
      v-if="total>12"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="pageHandle"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import newsItem from '@/views/brandTendency/newsItem.vue'
export default {
  data () {
    return {
      newsList: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      loading: true,
      brandName: ''
    }
  },
  props: {
    details: {
      type: Object
    }
  },
  components: {
    newsItem
  },
  created () {
    this.brandName = this.details.brandName
    this.loading = true
    this.getTendencyList()
  },
  methods: {
    getTendencyList (flag) {
      this.axios.post(api.getNewsBrandNewsSearch, {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        category: '',
        keyWord: this.brandName + '' + (this.details.brandEnglishName ? (',' + this.details.brandEnglishName) : '')
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            if (flag) {
              document.documentElement.scrollTop = 0
            }
            this.newsList = res.data.data.newsListVos
            this.total = res.data.data.newsAmount
          }
        })
    },
    pageHandle (val) {
      this.pageNum = val
      this.loading = true
      this.getTendencyList(true)
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-news
  background-color #272930
  padding 20px 35px 48px 35px
.brand-news-pagination
  margin-top 48px
  text-align center
.no-news-text
  text-align center
  margin-top 20px
  margin-right 24px
  line-height 18px
  font-size 14px
.noData
  padding-left 20px
  margin-top 30px
  color #fff
  text-align center
.no-list-data
  width 136px
  height 98px
  background-size 100%
</style>
