<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="storeDistribution"
  >
    <div class="simpleInfoPanel">
      <div
        v-show="isShowBrandDetail"
        class="analysis-wrap-tips"
      >
        <div
          v-if="lookNumber > 0"
          class="look-detail-wrap"
        >
          <div class="text">
            <span class="icon-small-tip"></span>
            您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
          </div>
          <div class="btn">
            <span @click="lookBrandDetail()">查看开关店分布</span>
          </div>
        </div>
        <div
          v-else-if="interestsData.totallookbrandrightnum > 0"
          class="look-detail-wrap"
        >
          <div class="text">
            <span class="icon-small-tip"></span>
            您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
          </div>
        </div>
        <div
          v-else
          class="noData-look-brand"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，您无查看权限。</p>
        </div>
      </div>
      <div
        class="city-distribution"
        v-show="isShowBrandContent"
      >
        <h3 class="w-title-gradients">开关店地域分布</h3>
        <div class="select-tab">
          <div class="shop-type">
            <div class="type-item">
              商业类型
              <span
                class="bigSize"
                @click="projectTypeClick('购物中心')"
                :class="[projectType === '购物中心' ? 'active': '']"
              >购物中心</span>
              <span
                class="bigSize"
                @click="projectTypeClick('独立百货')"
                :class="[projectType === '独立百货' ? 'active': '']"
              >连锁独立百货</span>
            </div>
            <div class="type-item">
              指标维度
              <span
                @click="normClick(10)"
                :class="[state === 10 ? 'active' : '']"
              >在营门店</span>
              <span
                @click="normClick(6)"
                :class="[tabDisabled ? 'tabDisabled' : state === 6 ? 'active' : '']"
              >新开门店</span>
              <span
                @click="normClick(1)"
                :class="[tabDisabled ? 'tabDisabled' : state === 1 ? 'active' : '']"
              >新关门店</span>
            </div>
          </div>
          <div class="shop-type">
            <div class="type-item">
              地域维度
              <span
                @click="regionTabClick(1)"
                :class="[regionalType === 1 ? 'active': '']"
              >省份</span>
              <span
                @click="regionTabClick(2)"
                :class="[regionalType === 2 ? 'active': '']"
              >城市</span>
              <span
                @click="regionTabClick(3)"
                :class="[regionalType === 3 ? 'active': '']"
              >区域</span>
            </div>
            <div class="type-item w-input-sty">
              时间范围
              <!-- <i
                @click="wholeTime"
                :class="[time ? '': 'active']"
              >全部时间</i>
              <el-date-picker
                popper-class="black-el-date-picker"
                v-model="time"
                @change="timeChange"
                value-format='yyyy-MM'
                type="month"
                size="small"
                placeholder="请选择时间"
              >
              </el-date-picker> -->
              <i></i>
              <el-select
                v-if="showSelect"
                placeholder="请选择时间范围"
                v-model="time"
                :disabled="timeDisabled"
                popper-class="w-block-select-down"
                size="medium"
                @change="handleTime"
              >
                <el-option
                  v-for="item in quarterlist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="breadcrumb"
            v-if="provinceName!=='全国' && regionalType === 1"
          >
            <span
              class="pointer"
              @click="backState"
            >全国</span>
            <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
            {{provinceName}}
          </div>
        </div>
        <div
          class="charts-wrap"
          v-show="regionalType === 1"
        >
          <div class="chart-contant">
            <div class="charts-left">
              <div
                id="map"
                :style="{width:'800px', height:'530px', left: '-50px'}"
              ></div>
            </div>
            <div class="charts-right">
              <div class="open-store-num">{{provinceName}}{{tableTypeTitle}}{{tableIndexTitle}}：<span>{{provinceTable.sumShopNum}}</span> 家</div>
              <div class="open-store-table">
                <table>
                  <thead>
                    <tr>
                      <td width="200">{{provinceName === '全国' ? '省份': '城市'}}名称</td>
                      <td width="200">门店数量</td>
                      <td width="200">数量占比</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in provinceTable.dataList"
                      :key="index"
                      @click="toMapDetail(item)"
                    >
                      <td width="200">{{item.name}}</td>
                      <td width="200">{{item.shopNum}}</td>
                      <td width="200">{{item.shopNumPercent}} %</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="w-page-sty"
                  v-if="provinceTable.total > 8"
                >
                  <el-pagination
                    background
                    :current-page="pageNum"
                    @current-change="provincePageChange"
                    layout="prev, pager, next"
                    :page-size="8"
                    :total="provinceTable.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="charts-wrap"
          v-show="regionalType === 2"
        >
          <!-- <div class="charts-title">城市散点图</div> -->
          <div class="chart-contant">
            <div class="charts-left">
              <div
                id="cityMap"
                :style="{width:'800px', height:'530px', left: '-50px'}"
              ></div>
            </div>
            <div class="charts-right">
              <div class="open-store-num">全国{{tableTypeTitle}}{{tableIndexTitle}}：<span>{{provinceTable.sumShopNum}}</span> 家</div>
              <div class="open-store-table">
                <table>
                  <thead>
                    <tr>
                      <td width="200">城市名称</td>
                      <td width="200">门店数量</td>
                      <td width="200">数量占比</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in provinceTable.dataList"
                      :key="index"
                      @click="toMapDetail(item)"
                    >
                      <td width="200">{{item.name}}</td>
                      <td width="200">{{item.shopNum}}</td>
                      <td width="200">{{item.shopNumPercent}} %</td>
                    </tr>
                  </tbody>
                </table>
                <div class="w-page-sty">
                  <el-pagination
                    background
                    :current-page="pageNum"
                    @current-change="provincePageChange"
                    layout="prev, pager, next"
                    :page-size="8"
                    :total="provinceTable.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="region-chart-wrap"
          v-show="regionalType === 3"
        >
          <div
            class="region-title"
            v-if="regionName === ''"
          >地域占比</div>
          <div
            class="region-title"
            v-else
          >
            <span
              @click="getWholeArea()"
              class="pointer"
            >全部区域</span>
            <i class="el-icon-arrow-right"></i>
            <span class="gray">{{regionName}}</span>
          </div>
          <div class="charts-bar">
            <div
              id="area-bar"
              v-show="showBar"
              :style="{width:'1200px', height:'380px'}"
            ></div>
            <div
              v-show="!showBar"
              class="no-recommend-brand"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>暂无相关区域数据</p>
            </div>
          </div>
        </div>
        <div
          class="region-wrap"
          v-if="areaList && areaList.length > 0"
        >
          <div class="region-head">
            <span>门店分布明细数据</span>
            <div class="region-right">
              <ul>
                <li
                  :class="{active:currentTab === index}"
                  :key="index"
                  :index="index"
                  v-for="(item, index) in areaList"
                  @click="getBrandInProList(item.regionId, index)"
                >{{item.regionName}}</li>
              </ul>
            </div>
          </div>
          <div class="shop-list">
            <ul class="proList">
              <li
                :key="index"
                :index="item.projectId"
                v-for="(item, index) in projectList"
              ><i>{{item.provinceName}}：</i>
                <div
                  class="city-list"
                  v-for="(city, key) in item.children"
                  :key="key"
                >
                  <span
                    :key="k"
                    v-for="(list, k) in city.projects"
                    @click="toProjectDetails(list.id)"
                  >{{list.name}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="cityList"
          v-if="tipStr!==''"
        >
          <i class="el-icon-warning"></i>
          <div v-html="tipStr"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
export default {
  name: 'storeDistribution',
  data () {
    return {
      loading: true,
      isShowBrandDetail: false,
      isShowBrandContent: false,
      brandId: 0,
      gbid: 0,
      projectType: '购物中心',
      state: 10,
      regionalType: 1,
      time: '',
      areaList: [],
      projectList: [],
      currentTab: 0,
      provinces: ['shanghai', 'hebei', 'shanxi', 'neimenggu', 'liaoning', 'jilin', 'heilongjiang', 'jiangsu', 'zhejiang', 'anhui', 'fujian', 'jiangxi', 'shandong', 'henan', 'hubei', 'hunan', 'guangdong', 'guangxi', 'hainan', 'sichuan', 'guizhou', 'yunnan', 'xizang', 'shanxi1', 'gansu', 'qinghai', 'ningxia', 'xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen', 'taiwan'],
      provincesText: ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门', '台湾'],
      myChart: null,
      provinceData: [],
      provinceTable: {},
      areaData: [],
      pageNum: 1,
      tableTypeTitle: '购物中心',
      tableIndexTitle: '在营门店',
      citysData: [],
      provinceName: '全国',
      regionName: '',
      provinceId: 0,
      regionId: 0,
      selectProvinceList: [],
      storeNum: '',
      brandName: '',
      showBar: true,
      quarterlist: [],
      showSelect: false,
      timeDisabled: true,
      tabDisabled: false
    }
  },
  props: {
    tipStr: {
      type: String
    },
    details: {
      type: Object
    },
    lookNumber: {
      type: Number
    },
    interestsData: {
      type: Object
    }
  },
  components: {
  },
  mounted () {
    this.$nextTick(() => {
      this.brandName = this.$route.query.brandName
      this.brandId = this.$route.query.id
      this.gbid = this.$route.query.gbid
      this.myChart = echarts.init(document.getElementById('map'))
      this.axios.post(api.getBrandDetailIsShow,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        }
      )
        .then((res) => {
          if (res.data.data) {
            this.getbrandAnalysisOfSeasonList().then((res) => {
              this.quarterlist = res.data.data
              this.time = this.quarterlist[0].value
              this.showSelect = true
              this.getStoreCityNumber()
              this.getStoreCityTableNumber()
              this.getProjectList()
            })
            this.isShowBrandContent = true
            this.isShowBrandDetail = false
          } else {
            this.isShowBrandContent = false
            this.isShowBrandDetail = true
          }
          this.loading = false
        })
    })
  },
  methods: {
    lookBrandDetail () {
      this.axios.post(api.getBrandLookRightDeduction,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        }
      )
        .then((res) => {
          if (res.data.data === 1) {
            this.isShowBrandDetail = false
            this.isShowBrandContent = true
            this.getbrandAnalysisOfSeasonList().then((res) => {
              this.quarterlist = res.data.data
              this.time = this.quarterlist[0].value
              this.showSelect = true
              this.getStoreCityNumber()
              this.getStoreCityTableNumber()
              this.getProjectList()
            })
            this.$emit('updateMarker', true)
          }
        })
    },
    projectTypeClick (name) {
      this.projectType = name
      this.getbrandAnalysisOfSeasonList().then((res) => {
        this.quarterlist = res.data.data
        this.time = this.quarterlist[0].value
        if (this.projectType === '独立百货') {
          this.state = 10
          this.timeDisabled = true
          this.tabDisabled = true
          this.tableIndexTitle = '在营门店'
        } else {
          this.tabDisabled = false
          if (this.state === 10) {
            this.timeDisabled = true
          } else {
            this.timeDisabled = false
          }
        }
        if (this.regionalType !== 3) {
          this.pageNum = 1
        }
        this.provinceData = []
        this.provinceId = 0
        this.regionId = 0
        this.currentTab = 0
        this.getStoreCityNumber()
        this.getProjectList()
        this.getStoreCityTableNumber()
        this.tableTypeTitle = name
      })
    },
    normClick (idx) {
      this.state = idx
      this.provinceId = 0
      this.currentTab = 0
      this.getbrandAnalysisOfSeasonList().then((res) => {
        this.quarterlist = res.data.data
        this.time = this.quarterlist[0].value
        if (this.state === 10) {
          this.tableIndexTitle = '在营门店'
          this.timeDisabled = true
        } else if (this.state === 6) {
          this.tableIndexTitle = '新开门店'
          this.timeDisabled = false
        } else if (this.state === 1) {
          this.tableIndexTitle = '新关门店'
          this.timeDisabled = false
        }
        this.provinceData = []
        this.getStoreCityNumber()
        this.getProjectList()
        if (this.regionalType !== 3) {
          this.pageNum = 1
          this.getStoreCityTableNumber()
        }
      })
    },
    regionTabClick (idx) {
      this.regionName = ''
      this.regionalType = idx
      this.provinceId = 0
      this.regionId = 0
      this.currentTab = 0
      if (this.regionalType === 1) {
        this.provinceData = []
        this.pageNum = 1
        this.getStoreCityNumber()
        this.getProjectList()
        this.getStoreCityTableNumber()
      } else if (this.regionalType === 2) {
        this.pageNum = 1
        this.getStoreCityNumber()
        this.getProjectList()
        this.getStoreCityTableNumber()
      } else if (this.regionalType === 3) {
        this.getStoreCityNumber()
        this.getProjectList()
      }
    },
    handleTime (val) {
      this.time = val
      this.provinceId = 0
      this.currentTab = 0
      if (val) {
        this.time = val
      } else {
        this.time = ''
      }
      this.provinceData = []
      if (this.regionalType !== 3) {
        this.pageNum = 1
        this.getStoreCityTableNumber()
      }
      this.getStoreCityNumber()
      this.getProjectList()
    },
    wholeTime () {
      this.time = ''
      this.getStoreCityNumber()
      this.getProjectList()
      if (this.regionalType !== 3) {
        this.pageNum = 1
        this.getStoreCityTableNumber()
      }
    },
    provincePageChange (val) {
      this.pageNum = val
      this.getStoreCityTableNumber()
    },
    getWholeArea () {
      this.regionId = 0
      this.regionName = ''
      this.currentTab = 0
      this.getStoreCityNumber()
      this.getProjectList()
    },
    getStoreCityNumber (Chinese_) {
      const params = {
        brand_id: this.brandId,
        gbid: this.gbid,
        projectType: this.projectType,
        provinceId: this.provinceId,
        regionId: this.regionId,
        regionalType: this.regionalType,
        state: this.state,
        time: this.time ? this.time : ''
      }
      this.axios.post(api.getBrandAnalysisOpenClose, params)
        .then((res) => {
          if (this.regionalType === 1) {
            if (this.provinceId === 0) {
              this.provinceData = []
              for (const item of res.data.data) {
                const obj = {}
                obj.value = item.shopNum
                obj.name = item.name
                obj.id = item.id
                this.provinceData.push(obj)
              }
              this.initEcharts('china', '中国')
              this.getStoreCityTableNumber()
            } else {
              this.selectProvinceList = []
              for (const item of res.data.data) {
                const obj = {}
                obj.value = item.shopNum
                obj.name = item.name + '市'
                obj.id = item.id
                this.selectProvinceList.push(obj)
              }
              this.initEcharts(Chinese_)
              this.getStoreCityTableNumber()
            }
          } else if (this.regionalType === 2) {
            this.citysData = res.data.data
            this.scatterCityMap()
          } else if (this.regionalType === 3) {
            this.areaData = res.data.data
            if (this.areaData && this.areaData.length > 0) {
              this.showBar = true
            } else {
              this.showBar = false
            }
            this.setBarData()
          }
        })
    },
    getStoreCityTableNumber () {
      const params = {
        brand_id: this.brandId,
        gbid: this.gbid,
        projectType: this.projectType,
        regionalType: this.regionalType,
        state: this.state,
        time: this.time ? this.time : '',
        provinceId: this.provinceId,
        pageNum: this.pageNum,
        pageSize: 8
      }
      this.axios.post(api.getBrandAnalysisOpenCloseList, params)
        .then((res) => {
          this.provinceTable = res.data.data
          this.storeNum = this.provinceTable.total
          this.loading = false
        })
    },
    getAreaList () {
      const params = {
        brand_id: this.brandId,
        gbid: this.gbid,
        projectType: this.projectType,
        provinceId: this.provinceId,
        regionId: this.regionId,
        regionalType: this.regionalType,
        state: this.state,
        time: this.time ? this.time : ''
      }
      return new Promise((resolve, reject) => {
        this.axios.post(api.getBrandOpenShopDistribution, params)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error('0'))
            }
          })
      })
    },
    getbrandAnalysisOfSeasonList () {
      return new Promise((resolve, reject) => {
        this.axios.post(api.brandAnalysisOfSeasonList)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error('0'))
            }
          })
      })
    },
    getProjectList () {
      this.getAreaList().then((res) => {
        this.areaList = res.data.data.list
        if (this.areaList.length > 0) {
          const params = {
            brand_id: this.brandId,
            gbid: this.gbid,
            projectType: this.projectType,
            provinceId: this.provinceId,
            regionId: res.data.data.list[0].regionId,
            regionalType: this.regionalType,
            state: this.state,
            time: this.time ? this.time : ''
          }
          this.axios.post(api.getBrandInProjectList, params)
            .then((res) => {
              this.projectList = res.data.data.list
            })
        }
      })
    },
    getBrandInProList (id, index) {
      this.currentTab = index
      const params = {
        brand_id: this.brandId,
        gbid: this.gbid,
        projectType: this.projectType,
        provinceId: this.provinceId,
        regionId: id,
        regionalType: this.regionalType,
        state: this.state,
        time: this.time ? this.time : ''
      }
      this.axios.post(api.getBrandInProjectList, params)
        .then((res) => {
          this.projectList = res.data.data.list
        })
    },
    toProjectDetails (id) {
      window.open('http://' + window.location.host + '/#/projectDetail?id=' + id + '&source=1', '_blank')
    },
    // 省份进城市地图
    initEcharts (pName, Chinese_) {
      let tooltipsName = ''
      if (this.state === 10) {
        tooltipsName = '在营门店'
      } else if (this.state === 6) {
        tooltipsName = '新开门店'
      } else if (this.state === 1) {
        tooltipsName = '新关门店'
      }
      let tmpSeriesData = []
      if (pName === 'china') {
        this.provinceName = '全国'
        tmpSeriesData = this.provinceData
      } else {
        this.provinceName = pName
        tmpSeriesData = this.selectProvinceList
      }
      const option = {
        title: {
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left'
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          x: 'right',
          y: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false }
          }
        },
        visualMap: {
          orient: 'horizontal',
          max: 0,
          min: 5,
          left: 120,
          text: ['高', '低'],
          textStyle: {
            color: '#fff'
          },
          calculable: false,
          inRange: {
            color: ['#4fe0ff', '#00d0fd']
            // color: ['#4fe0ff']
          }
        },
        series: [
          {
            name: tooltipsName,
            type: 'map',
            mapType: pName,
            roam: false,
            // 是否开启鼠标缩放和平移漫游
            itemStyle: {
              normal: {
                // 是图形在默认状态下的样式
                areaColor: ['#4fe0ff'],
                label: {
                  show: true,
                  // 是否显示标签
                  textStyle: {
                    color: 'rgba(255,255,255,1)'
                  }
                }
              },
              emphasis: {
                areaColor: ['#FF9D3F'],
                label: { show: true }
              }
            },
            data: tmpSeriesData,
            top: '3%'
            // 组件距离容器的距离
          }
        ]
      }
      this.myChart.setOption(option)
      this.myChart.off('click')
      if (pName === 'china') {
        this.myChart.on('click', (param) => {
          if (param.data.name === '北京' || param.data.name === '天津' || param.data.name === '重庆' || param.data.name === '上海') {
            let id = ''
            if (param.data.name === '北京') {
              id = 401
            } else if (param.data.name === '天津') {
              id = 402
            } else if (param.data.name === '重庆') {
              id = 654
            } else if (param.data.name === '上海') {
              id = 473
            }
            window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + id + '&cityName=' + param.data.name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
          } else {
            this.provinceId = param.data.id
            for (var i = 0; i < this.provincesText.length; i++) {
              if (param.name === this.provincesText[i]) {
                this.showProvince(this.provinces[i], this.provincesText[i])
                break
              }
            }
          }
        })
      } else {
        this.myChart.on('dblclick', () => {
          this.backState()
        })
        this.myChart.on('click', (param) => {
          if (param.data) {
            window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + param.data.id + '&cityName=' + param.data.name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
          }
        })
      }
    },
    backState () {
      this.provinceId = 0
      this.getStoreCityNumber()
      this.getProjectList()
    },
    // 散点城市地图
    scatterCityMap () {
      const mycharts = echarts.init(document.getElementById('cityMap'))
      let tooltipsName = ''
      if (this.state === 10) {
        tooltipsName = '在营门店'
      } else if (this.state === 6) {
        tooltipsName = '新开门店'
      } else if (this.state === 1) {
        tooltipsName = '新关门店'
      }
      const option = {
        // 整个画布背景
        backgroundColor: '',
        // 提示框组件
        tooltip: {
          trigger: 'item',
          confine: true,
          // 自定义提示信息
          formatter: (p) => {
            const dataCon = p.data
            const txtCon = dataCon.name + '<br>' + tooltipsName + '：' + dataCon.value[2]
            return txtCon
          }
        },
        // 左下lengend
        visualMap: {
          orient: 'horizontal',
          max: 0,
          min: 10,
          left: 120,
          text: ['高', '低'],
          textStyle: {
            color: '#fff'
          },
          calculable: false,
          inRange: {
            color: ['#4fe0ff', '#00d0fd']
            // color: ['#4fe0ff']
          }
        },
        // 地图区域样式
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            // 未激活样式
            normal: {
              areaColor: '#323c48',
              borderColor: '#111'
            },
            // 激活样式
            emphasis: {
              areaColor: '#2a333d'
            }
          }
        },
        // 数据
        series: [
          {
            name: '',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.citysData,
            symbolSize: 12,
            // 直接在点上显示标签
            label: {
              show: false,
              formatter: '{b}',
              // 文字的相对偏移
              offset: [15, -15]
            },
            // 标签的样式
            itemStyle: {
              emphasis: {
                borderColor: '#fff',
                borderWidth: 1
              }
            }
          }
        ]
      }
      mycharts.setOption(option)
      mycharts.on('click', (param) => {
        window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + param.data.id + '&cityName=' + param.data.name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
      })
    },
    // 柱形图
    setBarData () {
      const values = {
        value1: [],
        value2: [],
        name: [],
        id: []
      }
      const names = []
      this.areaData.sort((a, b) => { return b.shopNum - a.shopNum })
      for (const item of this.areaData) {
        names.push(item.name)
        values.value1.push(item.shopNum)
        values.value2.push(item.shopNumPercent)
        values.name.push(item.name)
        values.id.push(item.id)
      }
      // 双y轴实现从0值开始左右刻度线一致。设置坐标轴分割间隔, 最大值/分割段数
      const max1 = Math.max(...values.value1) ? Math.max(...values.value1) : 0
      const intervalValue1 = max1 / 5 ? max1 / 5 : null
      const max2 = Math.max(...values.value2) ? Math.max(...values.value2) : 0
      const intervalValue2 = max2 / 5 ? max2 / 5 : null
      let tooltipsName = ''
      if (this.state === 10) {
        tooltipsName = '在营门店'
      } else if (this.state === 6) {
        tooltipsName = '新开门店'
      } else if (this.state === 1) {
        tooltipsName = '新关门店'
      }
      const myChart = echarts.init(document.getElementById('area-bar'))
      const option = {
        color: ['#26CBE9'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            const html = params[0].name + '<br>' + tooltipsName + '：' + (params[0].data ? params[0].data : '-') + '<br>' + '数量占比：' + (params[1].data ? params[1].data : '-') + '%'
            return html
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: names,
            axisLabel: {
              algin: 'left'
            },
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            nameTextStyle: {
              color: 'rgba(226,226,226,0.1)',
              algin: 'center',
              margin: [0, 0, 0, 5]
            }
          }
        ],
        yAxis: [
          {
            // type: 'value',
            name: '门店数量',
            nameLocation: 'end',
            axisLabel: {
              formatter: '{value}'
            },
            min: 0,
            max: max1,
            splitNumber: 5,
            interval: intervalValue1,
            nameGap: 23,
            nameTextStyle: {
              color: '#e7e7e7',
              algin: 'right',
              padding: [5, 5, 15, 5]
            },
            axisLine: {
              lineStyle: {
                color: '#e7e7e7'
              }
            },
            splitLine: {
              show: true,
              color: '#e7e7e7',
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }, {
            name: '数量占比',
            nameLocation: 'end',
            nameTextStyle: {
              color: '#e7e7e7',
              algin: 'right',
              padding: [5, 5, 15, 5]
            },
            min: 0,
            max: max2,
            splitNumber: 5,
            interval: intervalValue2,
            axisLabel: {
              formatter: function (params) {
                return params.toFixed(2) + '%'
              },
              color: '#fff',
              textStyle: {
                fontSize: 12
              }
            },
            axisLine: {
              lineStyle: {
                color: '#e7e7e7'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)',
                type: 'dashed'
              }
            }
          }
        ],
        series: [
          {
            name: '直接访问',
            type: 'bar',
            barWidth: '15%',
            label: {
              normal: {
                show: true,
                position: 'top',
                color: '#e7e7e7',
                fontSize: 14,
                formatter: '{c}'
              },
              color: '#e7e7e7',
              formatter: '{c}',
              yAxisIndex: 0
            },
            data: values.value1
          }, {
            type: 'line',
            data: values.value2,
            itemStyle: {
              color: '#F89407'
            },
            // symbol: 'circle',
            yAxisIndex: 1
          }
        ]
      }
      myChart.setOption(option)
      myChart.off('click')
      myChart.on('click', (param) => {
        const name = values.name[param.dataIndex]
        const id = values.id[param.dataIndex]
        const regionArr = ['华东区', '华南区', '华北区', '西南区', '华中区', '西北区', '东北区']
        if (regionArr.indexOf(name) === -1) {
          this.regionId = id
          window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + id + '&cityName=' + name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
        } else {
          this.regionId = id
          this.regionName = name
          this.getStoreCityNumber()
          this.getProjectList()
        }
      })
    },
    // 展示对应的省
    showProvince (pName, Chinese_) {
      this.loadBdScript('$' + pName + 'JS', 'https://jscss.winshangdata.com/yzs/province/' + pName + '.js', () => {
        this.getStoreCityNumber(Chinese_)
        this.getProjectList()
      })
    },
    loadBdScript (scriptId, url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null
            callback()
          }
        }
      } else {
        script.onload = function () {
          callback()
        }
      }
      script.src = url
      script.id = scriptId
      document.getElementsByTagName('head')[0].appendChild(script)
    },
    toMapDetail (item) {
      if (this.regionalType === 1) {
        if (this.provinceId) {
          window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + item.id + '&cityName=' + item.name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
        }
      } else {
        window.open('http://' + window.location.host + '/#/brandDetail/brandMap?cityId=' + item.id + '&cityName=' + item.name + '&brandName=' + encodeURIComponent(this.details.brandName) + '&brandId=' + this.brandId + '&gbid=' + this.gbid + '&projectType=' + this.projectType + '&regionalType=' + this.regionalType + '&state=' + this.state + '&time=' + this.time, '_blank')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.storeDistribution
  .simpleInfoPanel
    width 100%
    border-radius 0 0 3px 3px
    padding 10px 0 20px 0
.charts-wrap
  .charts-title
    padding-left 30px
    color #fff
  .chart-contant
    display flex
    .charts-right
      width 430px
      .open-store-num
        font-size 16px
        color #fff
        font-weight 500
        span
          font-size 24px
      .open-store-table
        margin-top 25px
        table
          border 1px solid rgba(255, 255, 255, 0.05)
          thead
            tr
              width 100%
              height 42px
              line-height 42px
              td
                text-align center
                background-color rgba(255, 255, 255, 0.05)
                color #A5A5A7
                font-size 14px
          tbody
            tr
              width 100%
              height 53px
              line-height 53px
              border-bottom 1px solid rgba(255, 255, 255, 0.05)
              &:hover
                background-color rgba(255, 255, 255, 0.03)
                cursor pointer
              td
                text-align center
                color #fff
                font-size 14px
.region-chart-wrap
  padding 20px 0
  .region-title
    font-size 16px
    font-family PingFang SC
    font-weight 500
    padding-left 30px
    color #FFFFFF
    .gray
      color #A5A5A7
  #area-bar
    margin auto
.region-wrap
  margin 30px 20px
  padding-bottom 30px
  background-color #272930
  margin-top 80px
  border 1px solid rgba(255, 255, 255, 0.1)
  box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
  border-radius 3px
  .region-head
    width 100%
    line-height 42px
    color #fff
    font-size 18px
    margin-left 30px
    margin-top 20px
    margin-bottom 20px
    .region-right
      float right
      line-height 20px
      margin-top 10px
      margin-right 60px
      ul
        li
          display inline-block
          padding 4px 3px
          color #fff
          cursor pointer
          font-size 16px
          margin-left 15px
        .active
          color #FFA134
          border-radius 2px
          border-bottom 2px solid #FFA134
        li:hover
          color #FFA134
          border-radius 2px
          border-bottom 2px solid #FFA134
  .shop-list
    margin-top 20px
    .proList
      margin-left 30px
      li
        margin 0 20px 8px 70px
        color #aaaaaa
        // cursor pointer
        font-size 14px
        position relative
        i
          position absolute
          top 4px
          left -70px
          display inline-block
          font-style normal
        span
          margin-right 10px
          line-height 24px
          color #e5e5e5
          cursor pointer
          display inline-block
        span:hover
          color #fff
          text-decoration underline
.select-tab
  padding-left 30px
  margin-top 50px
  margin-bottom 90px
  .shop-type
    display flex
    margin-bottom 32px
    .type-item
      font-size 14px
      font-family PingFang SC
      font-weight bold
      color #FFFFFF
      margin-right 80px
      span
        display inline-block
        width 118px
        height 30px
        line-height 30px
        font-size 14px
        font-family PingFang SC
        font-weight 500
        color rgba(255, 255, 255, 0.5)
        border 1px solid #626265
        text-align center
        margin-right -1px
        cursor pointer
        &.active
          background #FFA134
          color #fff
          border-color #FFA134
      span.bigSize
        width 176px
      span:first-child
        border-radius 3px 0px 0px 3px
        margin-left 16px
      span:last-child
        border-radius 0px 3px 3px 0px
      i
        display inline-block
        margin-left 15px
.cityList
  position relative
  background-color rgba(255, 255, 255, 0.1)
  margin 0 20px
  margin-top 10px
  padding 10px 15px
  font-size 14px
  color #fff
  line-height 20px
  padding-left 30px
  border 1px dashed rgba(255, 255, 255, 0.6)
  i
    position absolute
    left 10px
    top 13px
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
.look-detail-wrap
  padding-top 120px
  .text
    width 530px
    height 38px
    line-height 38px
    font-size 14px
    margin auto
    background-color rgba(0, 0, 0, 0.1)
    color #fff
    i
      color #ffa134
    .icon-small-tip:before
      color #fff
      vertical-align middle
  .btn
    margin-top 35px
    span
      display inline-block
      width 164px
      height 28px
      background-color #ffa134
      color #fff
      border-radius 3px
      font-size 14px
      line-height 28px
      cursor pointer
.noData-look-brand
  img
    padding-top 120px
  p
    color #fff
    font-size 12px
    font-weight 500
    margin-top 30px
.no-recommend-brand
  border 1px solid rgba(226, 226, 226, 0.1)
  margin 20px 30px
  padding-bottom 40px
  padding-top 10px
.tabDisabled
  background rgba(98, 98, 101, 0.3)
  color rgba(255, 255, 255, 0.1)
  pointer-events none
.breadcrumb
  color #fff
  font-size 14px
</style>
