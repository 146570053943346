<template>
  <div
    class="dock-wrap"
    v-loading="loading"
    element-loading-text="加载中..."
  >
    <div class="dock-content">
      <div class="title">
        <h3 class="w-title-gradients">任务记录</h3>
        <div class="floor-item">
          <el-select
            v-model="projectName"
            @change="changeProject"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="floor-item2 floor-item">
          <el-select
            v-model="statesStr"
            @change="changeStates"
            popper-class="w-block-select-down"
            placeholder="请选择"
          >
            <el-option
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="recond-wrap">
        <div
          class="recond-item"
          v-for="(item, index) in brandList.dataList"
          :key="index"
        >
          <div class="body">
            <div class="body-head">
              <span
                class="w150"
                style="padding-left: 10px;"
              > 创建时间</span>
              <span
                class="w160"
                style="padding-left: 10px;"
              >项目名称</span>
              <span class="w210">落位铺位</span>
              <span class="w120">跟进人员</span>
              <span class="w120">招商状态</span>
              <span class="w120">最近跟进时间</span>
            </div>
            <div class="body-content">
              <span
                class="w150"
                style="padding-left: 10px;"
              > {{item.createTime}}</span>
              <span
                class="w160 ellipsis"
                style="padding-left: 10px;"
              >{{item.projectName}}</span>
              <span
                class="w210"
                v-if="item.berths && item.berths.length > 0"
              >
                <i
                  v-for="(list1, key1) in item.berths"
                  :key="key1"
                >{{key1 === 0 ? list1.berthNo: '、' + list1.berthNo}}</i>
              </span>
              <span
                class="w210"
                v-else
              >
                -
              </span>
              <span class="w120">{{item.followUserName?item.followUserName:'-'}}</span>
              <span class="w120"><em :style="{background: item.color}"></em>{{item.taskState}}</span>
              <span class="w150">{{item.lastFollowTime?item.lastFollowTime:'-'}}</span>
            </div>
          </div>
          <div class="body-btn">
            <span @click="lookInvestment(item)">跟进详情</span>
          </div>
        </div>
        <div
          class="no-recommend-brand"
          v-if="brandList.dataList && brandList.dataList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无招商任务跟进记录</p>
          <div
            class="edit-btn"
            @click="showAttractFunc"
          >修改招商进度</div>
        </div>
      </div>
      <div
        class="attract-wrap"
        v-if="attractShow"
      >
        <div class="attract-box">
          <div class="attract-head">
            <i
              class="el-icon-close"
              @click="hideAttract"
            ></i>
            <h3>修改招商进度确认</h3>
          </div>
          <div class="attract-content">
            <div class="add-investment-task-brand projectName">
              <el-select
                popper-class="floorSelectPoper"
                @change="editProjectChange"
                v-model="editProjectName"
                placeholder="请选择招商项目"
              >
                <el-option
                  v-for="item in editProjectList"
                  :key="item.projectId"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="content-text">
              <p>当前项目还未存在招商任务数据</p>
              <p>需要同步创建该品牌的跟进任务数据</p>
            </div>
          </div>
          <div class="attract-btns">
            <span @click="hideAttract">取消</span>
            <span
              class="sure"
              @click="editBerthTaskFunc()"
            >确定</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      attractShow: false,
      brandList: {},
      projectName: '所有项目',
      projectId: 0,
      selectProjectId: 0,
      projectList: [],
      statesStr: '所有状态',
      stateSelect: '',
      pageNum: 1,
      states: [{
        label: '所有状态',
        value: ''
      }, {
        label: '待跟进',
        value: '待跟进'
      }, {
        label: '已联系',
        value: '已联系'
      }, {
        label: '已看场',
        value: '已看场'
      }, {
        label: '已报价',
        value: '已报价'
      }, {
        label: '已预定',
        value: '已预定'
      }, {
        label: '已签约',
        value: '已签约'
      }, {
        label: '已搁置',
        value: '已搁置'
      }, {
        label: '无意向',
        value: '无意向'
      }],
      editProjectList: [],
      editProjectName: '',
      pubplan: true,
      editProjectId: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  props: {
    details: {
      type: Object
    }
  },
  created () {
    this.getBerthProjectFunc()
    this.getFollowList()
  },
  mounted () {
    const userAuthoritiesAdd = this.user.confIds.filter(item => {
      return item.id === 74
    })
    console.log('userAuthoritiesAdd', userAuthoritiesAdd)
    if (userAuthoritiesAdd.length) {
      this.pubplan = userAuthoritiesAdd[0].func.indexOf('add') !== -1
    } else {
      this.pubplan = false
    }
  },
  methods: {
    showAttractFunc () {
      if (!this.pubplan) {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      } else {
        this.attractShow = true
        this.getProjectNoTaskFunc()
        document.body.style.overflowY = 'hidden'
      }
    },
    hideAttract () {
      this.attractShow = false
      document.body.style.overflowY = 'auto'
    },
    changeStates (item) {
      this.stateSelect = item.value
      this.getFollowList()
    },
    changeProject (item) {
      this.selectProjectId = item.value
      this.getFollowList()
    },
    getProjectNoTaskFunc () {
      this.axios.post(api.getProjectByNoTask,
        {
          brandId: this.details.brandId,
          gbid: this.details.gbid
        })
        .then((res) => {
          this.editProjectList = res.data.data
          if (this.selectProjectId > 0) {
            let obj = {}
            obj = this.editProjectList.find(item => { return item.projectId === this.selectProjectId })
            this.editProjectId = obj.projectId
            this.editProjectName = obj.projectName
          } else {
            this.editProjectId = this.editProjectList[0].projectId
            this.editProjectName = this.editProjectList[0].projectName
          }
        })
    },
    editProjectChange (val) {
      if (typeof (val) !== 'string') {
        this.editProjectId = val
      }
    },
    editBerthTaskFunc () {
      // if (this.user.isadmin !== 0 && this.user.operateIds.indexOf(23) === -1) {
      //   this.$message.error('你当前暂无品牌跟进权限!')
      //   return
      // }
      if (!this.editProjectId) {
        this.$message.error('请选择你要发布招商任务的项目!')
        return
      }
      this.axios.post(api.editBerthTask, {
        brandId: this.details.brandId,
        gbid: this.details.gbid,
        name: this.details.brandName,
        projectId: this.editProjectId
      })
        .then((res) => {
          this.$message({
            type: 'success',
            message: '发布招商任务成功！'
          })
          this.$router.push({
            path: '/investmentFollowDetail',
            query: {
              id: res.data.data
            }
          })
        })
    },
    getFollowList () {
      const data = {
        berthId: '',
        berthNo: '',
        brandName: this.details.brandName,
        createTimeBeg: '',
        createTimeEnd: '',
        floor: '',
        followUserId: '',
        industry: '',
        pageNum: this.pageNum,
        pageSize: 10,
        projectId: this.selectProjectId,
        taskState: this.stateSelect === '所有状态' ? '' : this.stateSelect
      }
      data.pageSize = 10
      this.axios.post(api.getBerthTaskList, data)
        .then((res) => {
          this.brandList = res.data.data
          this.brandList.dataList = res.data.data.dataList.map((item) => {
            if (item.taskState === '待跟进') {
              item.color = '#FFA134'
            } else if (item.taskState === '已联系') {
              item.color = '#3494FF'
            } else if (item.taskState === '已看场') {
              item.color = '#26CBE9'
            } else if (item.taskState === '已报价') {
              item.color = '#FEF054'
            } else if (item.taskState === '已预定') {
              item.color = '#FE7429'
            } else if (item.taskState === '已签约') {
              item.color = '#16DA64'
            } else if (item.taskState === '已搁置') {
              item.color = '#C750FF'
            } else {
              item.color = '#999999'
            }
            return item
          })
          this.loading = false
        })
    },
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.projectList.unshift({ label: '所有项目', value: 0 })
        })
    },
    lookInvestment (item) {
      const invesmtentHref = this.$router.resolve({
        path: '/investmentFollowDetail',
        query: {
          id: item.id
        }
      })
      window.open('/' + invesmtentHref.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.dock-content
  background #272930
  padding-top 30px
  padding-bottom 30px
  .title
    height 44px
    position relative
    font-size 18px
    color #fff
    span
      position absolute
      left 0
      top 2px
      width 2px
      height 18px
      background #ffa134
    .floor-item
      position absolute
      right 30px
      top 0
      input
        border-color #ffa134
    .floor-item2
      position absolute
      right 210px
      top 0
      input
        border-color #ffa134
.recond-wrap
  margin-top 20px
  margin-left 35px
  margin-right 30px
  .recond-item
    height 94px
    display flex
    margin-bottom 30px
    .body
      font-size 14px
      border 1px solid rgba(226, 226, 226, 0.2)
      .body-head
        height 41px
        line-height 41px
        display flex
        background rgba(255, 255, 255, 0.1)
        span
          display inline-block
          color rgba(255, 255, 255, 0.6)
      .body-content
        height 41px
        display flex
        justify-content center
        align-items center
        span
          display inline-block
          color #fff
          em
            display inline-block
            width 10px
            height 10px
            border-radius 5px
            margin-right 5px
    .body-btn
      position relative
      height 92px
      width 185px
      border-top 1px solid rgba(226, 226, 226, 0.2)
      border-right 1px solid rgba(226, 226, 226, 0.2)
      border-bottom 1px solid rgba(226, 226, 226, 0.2)
      span
        position absolute
        left 44px
        top 33px
        display inline-block
        width 102px
        height 24px
        border 1px solid rgba(255, 161, 52, 1)
        border-radius 3px
        text-align center
        line-height 24px
        font-size 14px
        color rgba(255, 161, 52, 1)
        cursor pointer
.w210
  width 266px
.w160
  width 180px
.w120
  width 130px
.w150
  width 150px
.no-recommend-brand
  text-align center
  color #fff
  margin-top 30px
  padding-bottom 100px
  padding-top 30px
  p
    margin-top 20px
    font-size 14px
  .edit-btn
    font-style normal
    font-size 12px
    border 1px solid #ffa134
    border-radius 2px
    color #ffa134
    vertical-align bottom
    width 114px
    height 26px
    line-height 26px
    text-align center
    cursor pointer
    margin-right 10px
    margin auto
    margin-top 20px
.attract-wrap
  height 100%
  width 100%
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  z-index 1111
  .attract-box
    position absolute
    left 50%
    top 180px
    width 616px
    height 330px
    margin-left -308px
    background #ffffff
    border-radius 6px
    padding 20px
    box-sizing border-box
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    .attract-head
      h3
        font-size 18px
        color #333
        font-weight bold
    .attract-content
      .projectName
        width 100%
        background rgba(255, 161, 52, 0.1)
        color #995f1d
        margin-top 14px
        margin-bottom 12px
        height 40px
        line-height 40px
        font-size 16px
        padding-left 8px
        box-sizing border-box
      .content-text
        margin-top 50px
        text-align center
        color #333
        font-size 18px
        font-weight bold
        line-height 26px
    .attract-btns
      text-align center
      margin-top 30px
      span
        display inline-block
        width 200px
        height 40px
        line-height 40px
        border 1px solid #ffa134
        color #ffa134
        border-radius 4px
        font-size 16px
        font-weight 400
        margin 0 15px
        margin-top 20px
        cursor pointer
      .sure
        background #ffa134
        color #fff
</style>
