<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="brandSituation"
  >
    <div
      v-if="content"
      class="simpleInfoPanel"
    >
      <div
        class="brand-basic-msg"
        v-if="details.zongbuName!=='' || details.parentfirm!=='' || details.zongbuGuojia!=='' || details.webSite!=='' || details.zongbuCity!=='' || details.Address!=='' || details.dicBrandGrade!==null || details.createDate!=='' || details.firstTime!==''"
      >
        <h3 class="w-title-gradients">品牌基本信息</h3>
        <div class="brand-info">
          <div
            class="info-item"
            v-if="details.zongbuName!==''"
          >
            <span>所属企业：</span>
            <i>{{details.zongbuName}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.parentfirm!==''"
          >
            <span>所属集团：</span>
            <i>{{details.parentfirm}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.zongbuGuojia!==''"
          >
            <span>品牌国籍：</span>
            <i>{{details.zongbuGuojia}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.webSite!==''"
          >
            <span>品牌官网：</span>
            <a
              :href="details.webSite"
              target="_blank"
            >{{details.webSite}}</a>
          </div>
          <div
            class="info-item"
            v-if="details.zongbuCity!==''"
          >
            <span>总部城市：</span>
            <i>{{details.zongbuCity}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.dicBrandGrade"
          >
            <span>品牌档次：</span>
            <i>{{details.dicBrandGrade}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.brandClass"
          >
            <span>品牌级次：</span>
            <i>{{details.brandClass}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.createDate!==''"
          >
            <span>创立时间：</span>
            <i>{{details.createDate}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.firstTime!==''"
          >
            <span>首进购物中心时间：</span>
            <i>{{details.firstTime}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.address!==''"
          >
            <span>总部地址：</span>
            <i>{{details.address}}</i>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div
        class="brand-basic-msg"
        v-if="details.joinProjectNum !== 0 || details.kaiDianMode!=='' || details.listHzfs!=='' || details.qiXianMin !== '0' || details.renJunXFMin !== 0 || details.mianJiMin !== 0 || details.yearNum !== 0"
      >
        <h3 class="w-title-gradients">开店经营数据</h3>
        <div class="brand-info">
          <div
            class="info-item"
            v-if="details.joinProjectNum!==0"
          >
            <span>已开店数：</span>
            <i v-if="details.joinProjectNum !== 0">
              已进驻{{details.joinProjectNum}}家购物中心{{details.chainMarketNum>0?' + ' + details.chainMarketNum + '家百货':''}}
              <em @click="toTab('storeDistribution')">查看开店分布</em>
            </i>
            <i v-if="details.chainMarketNum !== 0 && details.joinProjectNum === 0">
              {{details.chainMarketNum>0?'已进驻' + details.chainMarketNum + '家百货':''}}
              <em @click="toTab('storeDistribution')">查看开店分布</em>
            </i>
          </div>
          <div
            class="info-item"
            v-if="details.kaiDianMode!==''"
          >
            <span>开店方式：</span>
            <i>{{details.kaiDianMode}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.listHzfs!==''"
          >
            <span>租赁方式：</span>
            <i>{{details.listHzfs}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.qiXianMin !== '0' && details.qiXianMax !== '0'"
          >
            <span>合作期限：</span>
            <i>{{details.qiXianMin}}-{{details.qiXianMax}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.renJunXFMin !== 0 && details.renJunXFMax !== 0 && details.isAccord === 2"
          >
            <span>客单价：</span>
            <i>{{details.kedanjiaMin}}-{{details.kedanjiaMax}}元</i>
          </div>
          <div
            class="info-item"
            v-if="details.renJunXFMin !== 0 && details.renJunXFMax !== 0 && details.isAccord === 1"
          >
            <span>人均消费：</span>
            <i>{{details.renJunXFMin}}-{{details.renJunXFMax}}元</i>
          </div>
          <div
            class="info-item"
            v-if="details.mianJiMin !== 0 && details.mianJiMax !== 0"
          >
            <span>开店面积：</span>
            <i>{{details.mianJiMin}}-{{details.mianJiMax}}平米</i>
          </div>
          <div
            class="info-item"
            v-if="details.yearNum !== 0"
          >
            <span>开店计划：</span>
            <i>今年计划开店{{details.yearNum}}家 <em @click="toTab('expandDemand')">查看拓展需求</em></i>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div
        class="brand-financing"
        v-if="guestGroup.financingDynamic && guestGroup.financingDynamic.length > 0"
      >
        <h3 class="w-title-gradients">品牌融资动态</h3>
        <div class="finan-wrap">
          <div
            class="finan-item"
            v-for="(item, index) in guestGroup.financingDynamic"
            :key="index"
          >
            <span
              class="line"
              v-if="(index+1) < guestGroup.financingDynamic.length"
            ></span>
            <span class="circle"><span></span></span> <i class="date">{{item.financingDate}}</i> <span class="round">{{item.stageName}}</span> 融资金额: <span class="money">{{item.money}}</span>{{item.investor}}
          </div>
        </div>
        <p class="tips">
          <i class="el-icon-warning-outline"></i>
          根据互联网上公开数据获得
        </p>
        <div class="clearfix"></div>
      </div>
      <div
        class="competitive-brand"
        v-if="guestGroup.sameBrandVO && guestGroup.sameBrandVO.length > 0"
      >
        <h3 class="w-title-gradients">同集团&竞品品牌</h3>
        <div class="brand-list">
          <ul>
            <li
              class="list-item"
              :key="index"
              v-for="(item, index) in guestGroup.sameBrandVO"
            >
              <!-- <span
                v-if="item.trend === 1"
                class="star-tag"
              ></span> -->
              <div>
                <span
                  @click="toDetails(item.brandId, questionState, item.gbId)"
                  class="item-image"
                  :style="{'background-image': 'url(' + item.brandLogo + ')'}"
                ></span>
              </div>
              <div
                class="item-brandName"
                :title="item.brandName + item.brandEnglishName"
                @click="toDetails(item.brandId, questionState, item.gbId)"
              >{{item.brandName}}{{item.brandEnglishName}}</div>
              <div class="item-info-need">
                <p @click="toDetails(item.brandId, questionState, item.gbId)">{{item.listIndustryType}}/{{item.areaMin}}-{{item.areaMax}}平米</p>
                <p
                  class="Benchmarking"
                  @click="toDetails(item.brandId, questionState, item.gbId)"
                >已进驻{{item.shopNum}}家购物中心{{item.chainMarketNum>0? ('+'+item.chainMarketNum + '家百货'):''}}</p>
              </div>
              <div
                v-if="item.tagname !== ''"
                class="brand-label-list"
              >
                <span class="ellipsis">{{item.tagname}}</span>
              </div>
              <span
                class="sameGroup"
                v-if="item.state === 2"
              >同集团</span>
              <span
                class="sameGroup2"
                v-if="item.state === 1"
              >竞品</span>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
      <div
        class="brand-update-wrap"
        v-if="brandList && brandList.length > 0"
      >
        <h3 class="w-title-gradients">数据更新记录</h3>
        <div class="brand-update-table">
          <table>
            <thead>
              <tr>
                <td width='240'>更新品牌</td>
                <td width='240'>更新类型</td>
                <td width='500'>更新内容</td>
                <td width='230'>最后更新时间</td>
              </tr>
            </thead>
            <tbody class="w-singed-table">
              <tr
                v-for="(item, index) in brandList"
                :key="index"
              >
                <td width='240'>{{item.brandName}}</td>
                <td width='240'>{{item.type}}</td>
                <td width='500'>{{item.msg}}</td>
                <td width='230'>{{item.updateTime}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="jurisdiction-wrap-no-data"
      v-if="defaultFalse"
    >
      <img
        src="@/assets/images/no_list_data.png"
        alt=""
      >
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'brandSituation',
  data () {
    return {
      loading: true,
      guestGroup: {},
      brandList: [],
      showMore: true,
      len: 0,
      content: false,
      defaultFalse: false
    }
  },
  props: {
    details: {
      type: Object
    }
  },
  components: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.axios.post(api.getBrandUpdateLog,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        })
        .then((res) => {
          this.brandList = res.data.data
        })
      this.axios.post(api.getBrandCustomerGroups,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        })
        .then((res) => {
          this.guestGroup = res.data.data
          this.loading = false
          if ((this.guestGroup.sameBrandVO && this.guestGroup.sameBrandVO.length > 0) || (this.brandList && this.brandList.length > 0) || this.details.zongbuName !== '' || this.details.parentfirm !== '' || this.details.zongbuGuojia !== '' || this.details.webSite !== '' || this.details.zongbuCity !== '' || this.details.Address !== '' || this.details.dicBrandGrade !== null || this.details.createDate !== '' || this.details.firstTime !== '' || this.details.joinProjectNum !== 0 || this.details.listHzms !== '' || this.details.listHzfs !== '' || this.details.qiXianMin !== '0' || this.details.renJunXFMin !== 0 || this.details.mianJiMin !== 0 || this.details.yearNum !== 0) {
            this.content = true
            this.defaultFalse = false
          } else {
            this.content = false
            this.defaultFalse = true
          }
        })
    },
    toTab (type) {
      this.$emit('tabClick', type)
    },
    toDetails (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=4&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brandSituation
  .simpleInfoPanel
    width 100%
    padding-top 25px
    padding-bottom 50px
    border-radius 0 0 3px 3px
    .brand-basic-msg
      margin-bottom 30px
      .brand-info
        padding 0 42px
        max-width 1190px
        margin-top 30px
        .info-item
          float left
          min-width 32%
          margin-bottom 12px
          margin-right 10px
          span
            font-size 14px
            font-weight 500
            color #A5A5A7
            margin-right 5px
          i
            font-size 14px
            font-weight 500
            color #FFFFFF
            em
              border 1px solid #FFA134
              color #FFA134
              font-size 12px
              cursor pointer
          a
            color #fff
.brand-financing
  .tips
    margin 37px 42px 48px 42px
    font-size 14px
    color #948e91
.finan-wrap
  margin-left 40px
  margin-top 33px
  color #fff
  font-size 14px
  .finan-item
    margin-bottom 30px
  .money
    margin-right 20px
    font-weight bold
  .date
    font-style normal
    font-size 12px
    display inline-block
    width 60px
  .circle
    display inline-block
    width 14px
    height 14px
    background rgba(255, 255, 255, 0.1)
    border-radius 50%
    vertical-align middle
    margin-right 5px
    position relative
    box-shadow 0 0 4px rgba(255, 255, 255, 0.1)
    span
      position absolute
      width 5px
      height 5px
      background #fff
      border-radius 50%
      left 4.5px
      top 4.5px
  .round
    display inline-block
    width 80px
    text-align center
    background linear-gradient(#F0D1AC, #D4AD74)
    border-radius 20px
    padding 2px 0
    margin-right 10px
.competitive-brand
  margin-bottom 30px
  .brand-list
    ul
      padding 10px 0 10px 0
      overflow hidden
  .list-item
    position relative
    width 198px
    height 160px
    background-color #fff
    margin 8px 8px 0 0
    float left
    cursor pointer
  .list-item:nth-child(6n)
    margin-right 0
  .item-image
    display inline-block
    width 55px
    height 53px
    background no-repeat center center
    background-size 100%
    margin-left 15px
    margin-top 10px
  .item-info-xx
    display inline-block
    position absolute
    top 40px
    left 78px
    width 68px
    height 22px
    text-align center
    line-height 22px
    font-size 12px
    cursor pointer
    border 1px solid #eee
    border-radius 4px
    color #333
    background #fff
  .item-info-need
    font-size 12px
    color #333
    margin-left 15px
    margin-top 10px
    p
      line-height 20px
      padding 1px 0
  .item-brandName
    position absolute
    top 10px
    left 80px
    font-weight 600
    width 120px
    height 55px
    overflow hidden
    text-overflow ellipsis
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    font-size 14px
    color #333
  .brand-list
    padding 10px 26px
  .sameGroup
    width 40px
    height 24px
    line-height 24px
    position absolute
    right 0
    top 0
    background-color #FFA134
    color #fff
    font-size 12px
    text-align center
  .sameGroup2
    width 40px
    height 24px
    line-height 24px
    position absolute
    right 0
    top 0
    background-color rgba(255, 113, 52, 1)
    color #fff
    font-size 12px
    text-align center
  .star-tag
    position absolute
    left 3px
    top 3px
    width 20px
    height 27px
    background url('~@/assets/images/star-label.png') no-repeat
    background-size 100%
    z-index 111
  .brand-label-list
    position absolute
    left 15px
    bottom 8px
    font-size 12px
    span
      display inline-block
      font-size 12px
      color #b8a794
      padding 4px 6px
      border-radius 6px
      background-color #fff8f0
      max-width 150px
.brand-update-wrap
  .brand-update-table
    margin 20px 32px 0 32px
    table
      table-layout fixed
      thead
        display block
        tr
          height 42px
          line-height 42px
          border-bottom 1px solid #353539
          td
            font-size 14px
            font-weight 400
            color #A5A5A7
      tbody
        display block
        max-height 430px
        width 100%
        overflow-y auto
        tr
          height 42px
          line-height 42px
          border-bottom 1px solid #353539
          td
            font-size 14px
            font-weight 400
            color #fff
.showBtn
  color #ffffff
  text-align center
  font-size 14px
  margin-top 20px
  cursor pointer
  text-decoration underline
.jurisdiction-wrap-no-data
  color #ffffff
  text-align center
  min-height 250px
  padding-top 80px
  background #272930
  img
    margin-bottom 20px
  p
    font-size 14px
    line-height 18px
</style>
