<template>
  <div class="brandDetail">
    <div class="wrap">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/selfBrandList?source=1' }"
        >云端品牌库</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">{{details.brandName || '--'}}</span>
      </div>
      <div class="simpleInfoPanel brandInfoPanel">
        <el-popover
          ref="popover1"
          placement="top"
          title=""
          width="100%"
          trigger="hover"
          content=""
        >
          <div class="applets-wrap">
            <div class="applets-flex">
              <div class="applets">
                <img
                  class="applets-img"
                  :src="wbmsMpUrl"
                  alt=""
                >
                <p>赢商云商管小程序</p>
              </div>
            </div>
          </div>
        </el-popover>
        <span
          class="microAppBtn"
          v-popover:popover1
        ></span>
        <div class="brandImg fl">
          <img
            @click="dialogTableVisible=true"
            v-if="details.brandLogo"
            class="brandLogo"
            :src="details.brandLogo"
          />
          <img
            @click="dialogTableVisible=true"
            v-if="!details.brandLogo && isLogo"
            class="brandLogo"
            src="@/assets/images/public/notPic.jpg"
          />
          <span @click="dialogTableVisible=true">更多门店内容</span>
          <img
            v-if="isShowMarker"
            class="identifiton"
            src="@/assets/images/identifiton.png"
            alt=""
          >
        </div>
        <div class="brandMoreInfo">
          <div class="fl">
            {{details.brandName || '--'}}
            {{details.brandEnglishName ? '(' + details.brandEnglishName + ')' : ''}}
            <span>{{details.isTuoZhan === 1 ? '拓展选址中' : '暂不拓展'}}</span>
          </div>
          <div class="fr">
            <!-- 5.2版本去掉 -->
            <!-- <span
              class="btn"
              @click="addDropBrand()"
            >+ 添加到落位方案</span>
            <span
              class="btn"
              @click="addInverstmentTask()"
            >+ 发布招商任务</span> -->
            <span
              v-if="hasExportOut && hasExportOut.length > 0"
              class="btn"
              @click="exportBrandToEnterprise()"
            >导出到企业品牌库</span>
            <span
              :title="[hasRemark === 0 ? '关注品牌，最新动态及时提醒' : '点击取消关注该品牌']"
              class="simpleBtn"
              :class="{'followed': hasRemark !== 0}"
              @click="followClick()"
            >
              <i
                class="icon-heart-shaped"
                :class="[hasRemark === 0 ? 'not-follow' : 'yes-follow']"
              >
              </i>{{hasRemark === 0 ? '关注' : '已关注'}}
            </span>
            <!-- 5.2版本去掉 -->
            <!-- <span
              v-if="isShowEditBrand"
              class="simpleBtn"
              @click="editBrand(details)"
            ><i class="icon-edit"></i>编辑</span> -->
          </div>
          <div class="clearfix"></div>
          <ul class="brandTagList">
            <li v-if="details.industryTypeName !== ''">{{details.industryTypeName}}</li>
            <li
              :key='index'
              v-for="(item, index) in tagNameList"
              v-show="tagNameList && tagNameList[0] != ''"
            >{{item}}</li>
            <li v-if="details.dicBrandGrade">{{details.dicBrandGrade}}</li>
            <li v-if="details.kaiDianMode">{{details.kaiDianMode}}</li>
            <li v-show="details.yearNum !== 0">
              <span>今年计划开店{{details.yearNum}}家</span>
            </li>
            <li
              class="style2"
              v-if="details.joinProjectNum !== 0"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
              >
                <span>已进驻{{details.joinProjectNum}}家购物中心{{details.chainMarketNum>0?'+' + details.chainMarketNum + '家百货':''}}</span>
                <div slot="content">基于1-4线城市3万平米及以上购物中心开店情况</div>
              </el-tooltip>
            </li>
            <li
              class="style2"
              v-if="details.chainMarketNum !== 0 && details.joinProjectNum === 0"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
              >
                <span>{{details.chainMarketNum>0?'已进驻' + details.chainMarketNum + '家百货':''}}</span>
                <div slot="content">基于1-4线城市3万平米及以上购物中心开店情况</div>
              </el-tooltip>
            </li>
            <template v-if="details && details.othertag">
              <li
                class="style1"
                v-for="(item, index) in otherTag"
                :key="'other' + index"
              >{{item}}
              </li>
            </template>
            <li
              class="style2"
              v-show="details.financing === 1"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
              >
                <span>资本青睐品牌</span>
                <div slot="content">近两年已获机构资金投入，未来发展提速可能性大的品牌</div>
              </el-tooltip>
            </li>
            <li
              v-for="(item, index) in customLabel"
              :key='"cutom" + index'
              v-show="customLabel && customLabel[0] !== ''"
              class="style2"
            >{{item}}
            </li>
            <li
              v-for="(item, index) in operationFeature"
              :key="'operation' + index"
              v-show="operationFeature && operationFeature[0] !== ''"
              class="style2"
            >{{item}}
            </li>
            <li
              class="style2"
              v-if="details.newBranktop"
            >
              {{details.newBranktop}}
            </li>
            <li
              class="style2"
              v-if="details.trend"
            >
              {{details.trendName}}
            </li>
            <li
              class="style2"
              v-if="details.zldts"
            >
              主力店品牌
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div
          class="pr"
          v-if="details.content"
        >
          <div
            class="brandDescribe"
            :class="{'heightLess': !isShowMore}"
            v-html="details.content"
          >
          </div>
          <span
            class="handleDescribe"
            :class="{'bottomMore': !isShowMore}"
            @click="isShowMore=!isShowMore"
          >
            <i :class="isShowMore?'icon-showLess':'icon-showMore'"></i>
          </span>
        </div>
        <div
          v-if="brandInfo && brandInfo.length !== 0"
          class="information-info"
        >
          <el-carousel
            height="30px"
            direction="vertical"
            :interval='5000'
            :autoplay="true"
          >
            <el-carousel-item
              v-for="(item, index) in brandInfo"
              :key="index"
            >
              <i class="icon-brandInfo"></i>
              品牌资讯 |
              <a
                class="w-white"
                href="javascript:;"
                target="_blank"
                @click="toNewsDetail(item)"
              >
                {{item.title}}
              </a>
              <span class="dateStyle">{{item.newsTime}}</span>
              <a
                @click="lookMore('news')"
                target="_blank"
              >查看更多</a>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div
        class="simpleInfoPanel mt15 brandMyspic"
        v-if="details.indexNums && details.indexNums.length > 0"
      >
        <span class="panelTitle"></span>
        <!-- <template>
          热度指数：<span class="num">{{details.indexNums.length?details.indexNums[0].score:'--'}}</span>
          <span class="numExplain">高于 {{details.indexNums.length?details.indexNums[0].percent:'--'}} % 同品类品牌</span>
          潜力指数：<span class="num">{{details.indexNums.length?details.indexNums[1].score:'--'}}</span>
          <span class="numExplain">高于 {{details.indexNums.length?details.indexNums[1].percent:'--'}} % 同品类品牌</span>
          营运指数：<span class="num">{{details.indexNums.length?details.indexNums[2].score:'--'}}</span>
          <span class="numExplain">高于 {{details.indexNums.length?details.indexNums[2].percent:'--'}} % 同品类品牌</span>
        </template> -->
        <template v-for="(item, index) in details.indexNums">
          <i
            :key="index"
            v-if="item.score > 0"
          >
            {{item.name}}：<span class="num mustFill">{{item.score}}</span>
            <span class="numExplain">高于 <i class="mustFill">{{item.percent}}%</i> 同类</span>
          </i>
        </template>
        <el-button
          class="fs12 btnCommon"
          @click="toBrandIndex()"
        >查看赢商品牌指数注释详情</el-button>
      </div>
      <div
        class="simpleInfoPanel no-box-shadow"
        id="navTop"
        :class="searchBarFixed"
      >
        <ul class="navMenuList">
          <li
            v-for="(item, index) in navMenuList"
            :key="index"
            :class="{
              'currentActive': item.value===navMenuValue,
              'hot': item.value==='dataAnalysis' || item.value==='news'}"
            @click="navMenuValue=item.value"
          >
            {{item.name}}
            {{dataNum.brandExpandNum && item.value==='expandDemand'?'('+dataNum.brandExpandNum+')':''}}
            {{dataNum.linkmanNum && item.value==='contactWay'?'('+dataNum.linkmanNum+')':''}}
            {{newsNember && item.value==='news'?'('+newsNember+')':''}}
            {{dataNum.openShopNum && item.value==='storeDistribution'?'('+dataNum.openShopNum+')':''}}
          </li>
        </ul>
      </div>
      <div id="content">
        <div
          v-if="details"
          :is="navMenuValue"
          :details="details"
          :lookNumber="lookNumber"
          :tipStr="tipStr"
          :interestsData="interestsData"
          @tabClick="tabClick"
          @updateNumber="updateNumber"
          @updateMarker="updateMarker"
        ></div>
      </div>
      <div
        v-if="pileList && pileList.length > 0"
        class="like-list-wrap"
      >
        <div>
          <h3 class="w-title-gradients">猜你喜欢</h3>
        </div>
        <div class="brand-list">
          <ul>
            <li
              v-for="(item, index) in pileList"
              :key="index"
              class="list-item"
            >
              <div>
                <span
                  @click="toDetails(item.brandId, questionState, item.gbid)"
                  class="item-image"
                  :style="{'background-image': 'url(' + item.brandLogo + ')'}"
                ></span>
              </div>
              <div
                class="item-brandName"
                :title="item.brandName + item.brandEnglishName"
                @click="toDetails(item.brandId, questionState, item.gbid)"
              >{{item.brandName}}{{item.brandEnglishName}}</div>
              <div class="item-info-need">
                <p @click="toDetails(item.brandId, questionState, item.gbid)">{{item.listIndustryType}}/{{item.mianJiMin}}-{{item.mianJiMax}}平米</p>
                <p
                  v-if="item.shopNum > 0"
                  @click="toDetails(item.brandId, questionState, item.gbid)"
                >已进驻{{item.shopNum}}家购物中心{{item.chainMarketNum>0? ('+'+item.chainMarketNum + '家百货'):''}}</p>
              </div>
              <div
                class="label"
                v-if="item.listIndustryType"
              >{{item.listIndustryType}}</div>
            </li>
          </ul>
        </div>
      </div>
      <brand-list-bottom></brand-list-bottom>
    </div>
    <div v-if="dropBrand">
      <add-drop
        v-if="details !== {}"
        :dropEdit="6"
        :brandDetails="details"
        @hideAddDropFunc="hideAddDropFunc"
      ></add-drop>
    </div>
    <div v-if="invesTask">
      <inves-task
        v-if="details !== {}"
        :taskEdit='6'
        :brandDetails="details"
        @hideTaskFunc="hideTaskFunc"
      ></inves-task>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogTableVisible"
    >
      <el-carousel
        :interval="4000"
        type="card"
      >
        <el-carousel-item
          v-for="(item, index) in brandImg"
          :key="index"
        >
          <img
            :src="item"
            width="100%"
            height="100%"
          >
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import brandSituation from '@/views/brandDetail/components/brandSituation.vue'
import storeDistribution from '@/views/brandDetail/components/storeDistribution.vue'
import dataAnalysis from '@/views/brandDetail/components/dataAnalysis.vue'
import expandDemand from '@/views/brandDetail/components/expandDemand.vue'
import contactWay from '@/views/brandDetail/components/contactWay.vue'
import news from '@/views/brandDetail/components/news.vue'
import cooperationInfo from '@/views/brandDetail/components/cooperationInfo.vue'
import merchantsRecords from '@/views/brandDetail/components/merchantsRecords.vue'
import brandListBottom from '@/views/brandTendency/brandListBottom.vue'
import invesTask from '@/views/workBench/components/investmentTask.vue'
import addDrop from '@/views/workBench/components/addDropBrand.vue'
export default {
  name: 'brandDetail',
  components: {
    brandSituation,
    storeDistribution,
    dataAnalysis,
    expandDemand,
    contactWay,
    news,
    cooperationInfo,
    merchantsRecords,
    brandListBottom,
    invesTask,
    addDrop
  },
  data () {
    return {
      details: {},
      tagNameList: [],
      otherTag: [],
      operationFeature: [],
      customLabel: [],
      brandImg: [],
      isShowMore: false,
      dataLoading: true,
      dialogTableVisible: false,
      brandInfo: [],
      navMenuValue: 'brandSituation',
      navMenuList: [
        {
          name: '品牌概况',
          value: 'brandSituation'
        },
        {
          name: '开关店分布',
          value: 'storeDistribution'
        },
        {
          name: '品牌分析',
          value: 'dataAnalysis'
        },
        {
          name: '拓展需求',
          value: 'expandDemand'
        },
        {
          name: '联系方式',
          value: 'contactWay'
        },
        {
          name: '新闻资讯',
          value: 'news'
        }
        // 5.2版本去掉
        // {
        //   name: '合作信息',
        //   value: 'cooperationInfo'
        // },
        // {
        //   name: '招商对接记录',
        //   value: 'merchantsRecords'
        // }
      ],
      lookNumber: 0,
      pileList: [],
      tipStr: '',
      hasRemark: 0,
      searchBarFixed: 'mt15',
      invesTask: false,
      dropBrand: false,
      dataNum: {
        brandExpandNum: '', // 拓展需求数
        linkmanNum: '', // 联系方式数
        newsNum: '', // 新闻资讯数
        openShopNum: '' // 在营业店铺数量
      },
      interestsData: {},
      isShowEditBrand: false,
      isShowMarker: false,
      pubplan: false,
      addbrand: false,
      userAuthorities: [],
      userAuthoritiesAdd: [],
      newsNember: 0,
      isLogo: false,
      hasExportOut: [],
      downList: [],
      wbmsMpUrl: 'https://img3.winshang.com/wbms/wbms-applet.jpg'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 70
    })
    if (this.userAuthorities.length) {
      this.addbrand = this.userAuthorities[0].func.indexOf('addbrand') !== -1
    }
    this.userAuthoritiesAdd = this.user.confIds.filter(item => {
      return item.id === 74
    })
    if (this.userAuthoritiesAdd.length) {
      this.pubplan = this.userAuthoritiesAdd[0].func.indexOf('add') !== -1
    }
    this.hasExportOut = this.user.confIds.filter(item => {
      return item.id === 180
    })
    this.interestsData = JSON.parse(localStorage.getItem('wisdom_interests'))
    this.getDataBrandDetail()
    this.getBrandDetailLookNumFunc()
    this.getBrandTip()
    if (this.$route.query.id !== '0') {
      this.axios.post(api.getPileList, { pid: this.$route.query.id, pageSize: '12' })
        .then((res) => {
          this.pileList = res.data.data.list
        })
    }
    this.axios.post(api.getBrandRights)
      .then((res) => {
        if (res.data.data.managingBrands === 0) {
          this.isShowEditBrand = false
        } else {
          this.isShowEditBrand = true
        }
      })
    if (this.$route.query.from) {
      if (!this.dataNum.openShopNum) {
        this.navMenuValue = 'brandSituation'
      } else {
        this.navMenuValue = this.$route.query.from
      }
    }
    this.axios.post(api.getBrandDetailIsShow,
      {
        brand_id: this.$route.query.id,
        source: this.$route.query.source,
        gbid: this.$route.query.gbid
      }
    )
      .then((res) => {
        if (res.data.data) {
          this.isShowMarker = true
        } else {
          this.isShowMarker = false
        }
      })
    window.addEventListener('scroll', this.handleScroll)
    this.setMpUrl()
  },
  methods: {
    updateMarker (type) {
      if (type) {
        this.isShowMarker = true
      }
    },
    hasDataAnalysis () {
      const params = {
        brand_id: this.$route.query.id,
        gbid: this.$route.query.gbid,
        cityID: '',
        cityShangYeLevel: '',
        provinceID: '',
        regionID: ''
      }
      this.axios.post(api.getBrandAnalyzeHaveDate, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (!resData) {
            if (!this.dataNum.openShopNum) {
              this.deleteNavMenuListItem('storeDistribution')
              this.navMenuValue = 'brandSituation'
            } else {
              this.deleteNavMenuListItem('expandDemand')
            }
          }
        }
      })
    },
    getDataNum () {
      const params = {
        keyWord: [this.details.brandName, this.details.brandEnglishName].join(','),
        brand_id: this.$route.query.id,
        gbid: this.$route.query.gbid,
        source: this.$route.query.source
      }
      this.axios.post(api.getBrandDetailsNum, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.dataNum.brandExpandNum = resData.brandExpandNum
          this.dataNum.linkmanNum = resData.linkmanNum
          this.dataNum.newsNum = resData.newsNum
          this.dataNum.openShopNum = resData.openShopNum
          if (!this.dataNum.openShopNum) {
            this.deleteNavMenuListItem('storeDistribution')
            this.navMenuValue = 'dataAnalysis'
            this.dataNumLoading = false
          }
          if (!this.dataNum.brandExpandNum) {
            this.deleteNavMenuListItem('expandDemand')
            this.navMenuValue = 'brandSituation'
            this.dataNumLoading = false
          }
          this.hasDataAnalysis() // 判断数据分析是否有数据
        }
      })
    },
    deleteNavMenuListItem (name) {
      for (let i = 0; i < this.navMenuList.length; i++) {
        if (this.navMenuList[i].value === name) {
          this.navMenuList.splice(i, 1)
        }
      }
    },
    handleScroll (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = document.querySelector('#navTop').offsetTop || 0
      const contentTop = document.querySelector('#content').offsetTop
      if (scrollTop > offsetTop) {
        this.searchBarFixed = 'isFixed'
      }
      if (scrollTop < contentTop) {
        this.searchBarFixed = 'mt15'
      }
    },
    lookMore (type) {
      this.navMenuValue = type
    },
    tabClick (type) {
      this.navMenuValue = type
    },
    getBrandDetailLookNumFunc () {
      this.axios.post(api.getBrandDetailLookNum)
        .then((res) => {
          this.lookNumber = res.data.data
        })
    },
    getDataBrandDetail () {
      const params = {
        brand_id: this.$route.query.id,
        gbid: this.$route.query.gbid,
        source: this.$route.query.source
      }
      this.axios.post(api.getBrandExpandDemand, params).then(res => {
        if (res.data.code === 0) {
          this.details = res.data.data
          this.hasRemark = this.details.hasRemark
          if (res.data.data.tagname) {
            this.tagNameList = res.data.data.tagname.split(',')
          }
          if (!this.details.brandLogo) {
            this.isLogo = true
          }
          // this.otherTag = res.data.data.othertag.split(',')
          if (res.data.data.othertag !== '') {
            // this.selectOther = res.data.othertag.split(',')
            const arr = []
            const otherArr = res.data.data.othertag.split(',')
            for (let i = 0; i < otherArr.length; i++) {
              if (otherArr[i].replace(/\s+/g, '') !== '') {
                arr.push(otherArr[i])
              }
            }
            this.otherTag = arr
          }
          if (res.data.data.operationFeature) {
            this.operationFeature = res.data.data.operationFeature.split(',')
          }
          // this.customLabel = res.data.data.customLabel ? res.data.data.customLabel.split(',') : []
          if (res.data.data.customLabel !== '') {
            const selfArr = res.data.data.customLabel.split(',')
            const customArr = []
            for (let i = 0; i < selfArr.length; i++) {
              if (selfArr[i].replace(/\s+/g, '') !== '') {
                customArr.push(selfArr[i])
              }
            }
            this.customLabel = customArr
          }
          this.getDataNum()
          this.getDataBrandInfo()
          this.getNewNmberFunc()
        }
      })
      this.axios.post(api.getBrandPicList, params).then(res => {
        if (res.data.code === 0) {
          this.brandImg = res.data.data
        }
      })
    },
    getNewNmberFunc () {
      const params = {
        keyWord: this.details.brandName + '' + (this.details.brandEnglishName ? (',' + this.details.brandEnglishName) : '')
      }
      this.axios.post(api.brandNewsSearch, params).then(res => {
        this.newsNember = res.data.data.newsAmount
      })
    },
    getDataBrandInfo () {
      const params = {
        pageNum: 1,
        pageSize: 3,
        category: '',
        keyWord: this.details.brandName + '' + (this.details.brandEnglishName ? (',' + this.details.brandEnglishName) : '')
      }
      this.axios.post(api.brandNewsSearch, params).then(res => {
        this.brandInfo = res.data.data.newsListVos
        // if (res.data.code === 0) {
        // if (res.data.data.newsListVos.length) {
        // }
        // }
      })
    },
    updateNumber () {
      this.axios.post(api.getBrandDetailLookNum)
        .then((res) => {
          this.lookNumber = res.data.data
        })
    },
    toDetails (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=1' + '&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    },
    toNewsDetail (item) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent(item.title),
          newsId: item.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    // 获取品牌分析tips
    getBrandTip () {
      this.axios.post(api.getShopCityTip)
        .then((res) => {
          this.tipStr = res.data.data
        })
    },
    toBrandIndex () {
      let rightList = []
      rightList = this.user.confIds.filter(item => {
        return item.id === 106
      })
      if (rightList.length) {
        window.open('http://' + window.location.host + '/#/brandDetail/brandIndex?id=' + this.$route.query.id + '&source=4&questionState=0' + '&gbid=' + this.$route.query.gbid, '_blank')
      } else {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      }
    },
    addDropBrand () {
      if (!this.addbrand) {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      } else {
        this.dropBrand = true
        document.body.style.overflowY = 'hidden'
      }
    },
    hideAddDropFunc () {
      this.dropBrand = false
      document.body.style.overflowY = 'auto'
    },
    // 点击发布招商任务
    addInverstmentTask () {
      if (!this.pubplan) {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      } else {
        this.invesTask = true
        document.body.style.overflowY = 'hidden'
      }
    },
    hideTaskFunc () {
      this.invesTask = false
      // if (this.currentTab === 'docking') {
      //   this.keyFlag = !this.keyFlag
      // }
      document.body.style.overflowY = 'auto'
    },
    followClick () {
      if (this.hasRemark === 0) {
        this.axios.post(api.followBrand,
          {
            brandId: this.$route.query.id,
            uid: this.user.userId,
            gbid: parseInt(this.$route.query.gbid)
          })
          .then((res) => {
            this.hasRemark = 1
          })
      } else if (this.hasRemark === 1) {
        this.axios.post(api.cancalFollowBrand,
          {
            brandId: this.$route.query.id,
            uid: this.user.userId,
            gbid: parseInt(this.$route.query.gbid)
          })
          .then((res) => {
            this.hasRemark = 0
          })
      }
    },
    exportBrandToEnterprise () {
      // const list = JSON.parse(JSON.stringify(this.selfList))
      // this.downList = []
      // for (const item of list.dataList) {
      //   if (item.check) {
      //     const obj = {}
      //     obj.brandId = item.brandId
      //     obj.gbid = item.gbid
      //     this.downList.push(obj)
      //   }
      // }
      const obj = {}
      obj.brandId = this.$route.query.id
      obj.gbid = this.$route.query.gbid
      this.downList.push(obj)
      if (this.downList.length > 0) {
        this.$confirm('是否确认将品牌导到企业品牌库?', '品牌导出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning',
          // center: true,
          iconClass: '',
          customClass: 'custom-export'
        }).then(() => {
          this.downAllExcel()
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请选择要导出的品牌！'
        })
      }
    },
    downAllExcel () {
      // const h = this.$createElement
      this.axios.post(api.exportBrandToEnterpriseBrandTable, this.downList)
        .then((res) => {
          if (res.data.code === 0) {
            const number = res.data.data
            const h = this.$createElement
            this.$msgbox({
              title: '品牌导出',
              message: h('p', null, [
                h('p', { style: 'color: #fff;' }, '导出成功，可在企业品牌库查看、管理'),
                h('p', { style: 'color: #fff; margin-top: 10px; padding: 0 50px;' }, number > 0 ? '注：其中' + number + '个品牌是未使用查看权益的，故拓展需求+联系方式数据无法导出，后续如有查看则可导出。' : '')
              ]),
              center: true,
              customClass: 'export-brand',
              showCancelButton: false,
              confirmButtonText: '确定',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                } else {
                  done()
                }
              }
            })
          }
        })
    },
    editBrand (item) {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8030'
      }
      window.open(temUrl + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
    },
    setMpUrl () {
      if (document.domain.indexOf('wbms1') !== -1) {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/demo-wbms-applet.jpeg'
      } else if (document.domain.indexOf('wbms2') !== -1) {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/pre-wbms-applet.jpeg'
      } else {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/wbms-applet.jpg'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.brandDetail
  overflow hidden
  .hot:after
    top 14px
    right -33px
  .wrap
    width 1280px
    margin 10px auto
    margin-bottom 40px
    .simpleInfoPanel
      color #fff
      width 100%
      position relative
      .btnCommon:hover, .btnCommon:focus
        border 1px solid #fff !important
        color #fff !important
      .brandImg
        width 106px
        height 96px
        position relative
        margin 23px 0 0 32px
        .brandLogo
          width 106px
          height 96px
          cursor pointer
        span
          display inline-block
          width 100%
          height 24px
          line-height 24px
          color #fff
          text-align center
          cursor pointer
          font-size 12px
          position absolute
          left 0
          bottom 0
          background rgba(0, 0, 0, 0.7)
        .identifiton
          position absolute
          left 0
          top 0
      .brandMoreInfo
        width calc(100% -160px)
        margin-top 28px
        overflow hidden
        .fl
          margin-left 20px
          font-size 24px
          span
            font-size 12px
            background rgba(54, 255, 177, 0.15)
            display inline-block
            padding 3px
            color #1EFF98
            vertical-align middle
            margin-left 10px
        .fr
          margin-right 10px
          span
            display inline-block
            font-size 12px
            border-radius 2px
            height 20px
            line-height 20px
            padding 0 16px
            cursor pointer
            margin-right 16px
          span.btn
            border 1px solid #FFA134
            color #FFA134
          span.simpleBtn
            background rgba(255, 255, 255, 0.1)
        ul.brandTagList
          margin-top 10px
          font-size 12px
          li
            display inline-block
            background rgba(255, 255, 255, 0.1)
            color rgba(255, 255, 255, 0.7)
            height 22px
            line-height 22px
            border-radius 11px
            padding 0 16px
            margin-left 18px
            margin-top 10px
          li.style1
            background rgba(222, 194, 167, 0.1)
            color #FFC690
          li.style2
            background rgba(255, 88, 36, 0.1)
            color #FE8F69
      .brandDescribe
        position relative
        font-size 12px
        box-sizing border-box
        width 95%
        line-height 22px
        margin 24px auto
        border 1px dashed rgba(255, 255, 255, 0.3)
        padding 10px
        padding-right 30px
        border-radius 3px
        height auto
        font-weight 300
        color #fff !important
        p
          color #fff !important
      .heightLess
        height 60px
        text-overflow ellipsis
        overflow hidden
        word-break break-all
      .bottomMore
        bottom 10px !important
      .handleDescribe
        font-size 12px
        position absolute
        right 50px
        bottom 8px
        z-index 999
        cursor pointer
        color rgba(255, 161, 52, 1)
        background #272930
      .dateStyle
        color rgba(255, 255, 255, 0.5)
        font-size 12px
        margin 0 10px
    .no-box-shadow
      border-radius 3px 3px 0 0
      box-shadow none
    .brandInfoPanel
      // min-height 240px
      .microAppBtn
        display inline-block
        background-image url('~@/assets/images/brandDetail/microPhone.png')
        background-repeat no-repeat
        cursor pointer
        width 28px
        height 28px
        position absolute
        top 0
        right 0
      .icon-brandInfo
        margin-left 40px
      .information-info
        font-size 12px
        margin-bottom -5px
        // margin-top 15px
    .brandMyspic
      box-sizing border-box
      height 50px
      line-height 50px
      background linear-gradient(90deg, rgba(255, 130, 57, 0.14) 0%, rgba(39, 41, 48, 0.5) 80%)
      font-size 14px
      &:before
        content ''
        display inline-block
        width 1px
        height 32px
        background rgba(255, 255, 255, 0.1)
        position relative
        top 9px
        left 127px
      .panelTitle
        display inline-block
        width 140px
        height 18px
        background-image url('~@/assets/images/brandDetail/brandMyspic.png')
        background-repeat no-repeat
        background-position 24px
      span.num
        font-size 18px
        font-weight 800
        // color #FFA134
      span.numExplain
        color rgba(255, 255, 255, 1)
        margin 0 18px 0 10px
      .el-button
        position absolute
        right 20px
        top 12px
        width auto
        height 28px
        padding 0 7px !important
        border-radius 2px
        font-size 12px
        font-weight 400
    .navMenuList
      height 47px
      line-height 47px
      margin-top 10px
      border-bottom 1px solid rgba(226, 226, 226, 0.1)
      li
        display inline-block
        margin 0 24px
        cursor pointer
        position relative
        font-size 18px
        &:hover
          border-bottom 2px solid #FFA134
      li.currentActive:before
        content ''
        width 100%
        height 2px
        background #FFA134
        position absolute
        bottom -1px
        left 0
.brandDetail>>>
  .el-dialog
    width 70%
    .el-carousel__container
      height 400px
  .el-dialog, .el-pager li
    background none
  .el-carousel__item:nth-child(2n)
    background-color #99a9bf
  .el-carousel__item:nth-child(2n+1)
    background-color #d3dce6
.like-list-wrap
  background-color #272930
  margin-top 16px
  // margin-bottom 16px
  padding-top 24px
  padding-bottom 40px
  .brand-list
    margin-top 38px
    ul
      width 1200px
      margin auto
      li
        display inline-block
        cursor pointer
        width 220px
        vertical-align top
        position relative
        width 250px
        height 150px
        background-color #fff
        margin 10px
        width 220px
        height 160px
        overflow hidden
        .item-image
          display inline-block
          width 55px
          height 53px
          background no-repeat center center
          background-size 100%
          margin-left 15px
          margin-top 10px
        .item-brandName
          position absolute
          top 10px
          left 80px
          font-weight 600
          width 130px
          height 33px
          overflow hidden
          text-overflow ellipsis
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          font-size 14px
          color #333
        .item-info-need
          font-size 12px
          color #333
          margin-left 15px
          margin-top 10px
          p
            line-height 20px
            padding 2px 0
        .label
          position absolute
          bottom 10px
          left 12px
          background #FFF9F3
          border-radius 9px
          padding 3px 8px
          color #C9BAAB
          font-size 12px
.not-follow
  color #fff
.yes-follow
  color #ffa134
.followed
  background rgba(255, 161, 52, 0.1) !important
  color #ffa134
.isFixed
  position fixed !important
  top 0px
  z-index 11111
  width 1280px !important
  margin-top 0
.applets-wrap
  text-align center
</style>

<style lang="stylus">
.export-brand
  width 630px
</style>
