<template>
  <div v-loading='loading'>
    <div class="cooperation">
      <div
        class="coop-head-tip"
        v-show="showImg"
      >
        合作信息页面说明：品牌合作信息需要基于对接企业方品牌商户的经营数据后才能正常使用，当前页面用来展示数据对接后实现的效果，仅供参考。
      </div>
      <div
        class="coop-head"
        v-if="!showImg"
      >
        <ul>
          <li>
            <p class="p1">{{overData.projectNum}}个</p>
            <p class="p2">当前进驻项目</p>
          </li>
          <li>
            <p class="p1">{{overData.signedArea}}m²</p>
            <p class="p2">预计总计租面积</p>
          </li>
          <li>
            <p class="p1">{{overData.rentYear}}万</p>
            <p class="p2">预计年度总租金</p>
          </li>
        </ul>
      </div>
      <div
        class="coop-head"
        v-else
      >
        <ul>
          <li>
            <p class="p1">2个</p>
            <p class="p2">当前进驻项目</p>
          </li>
          <li>
            <p class="p1">899.08m²</p>
            <p class="p2">预计总计租面积</p>
          </li>
          <li>
            <p class="p1">57.98万</p>
            <p class="p2">预计年度总租金</p>
          </li>
        </ul>
      </div>
      <div class="clearfix"></div>
      <div v-if="showImg">
        <div class="coop-content">
          <ul class="coop-list">
            <li
              v-for="(item, index) in 3"
              :key="index + 'item1'"
            >
              <div class="coop-list-head">
                <span class="text-bold">
                  台州黄岩吾悦广场
                </span>
                <p>
                  <span>
                    楼宇楼层：A座/F3
                  </span>
                  <span>
                    铺位号：3023
                  </span>
                </p>
                <span class="coop-tip active">生效中</span>
              </div>
              <div class="coop-list-info">
                <div class="coop-list-left">
                  <span>合同号： 32645421024535421</span>
                  <span>合同有效日期：2020-09-16 至 2023-09-16 </span>
                  <span>总合同面积：278m²</span>
                  <span>合同说明：-</span>
                </div>
              </div>
            </li>
            <li
              v-for="(item, index) in 6"
              :key="index + 'item2'"
            >
              <div class="coop-list-head">
                <span class="text-bold">
                  台州黄岩吾悦广场
                </span>
                <p>
                  <span>
                    楼宇楼层：A座/F3
                  </span>
                  <span>
                    铺位号：3023
                  </span>
                </p>
                <span class="coop-tip">已失效</span>
              </div>
              <div class="coop-list-info">
                <div class="coop-list-left">
                  <span>合同号： 32645421024535421</span>
                  <span>合同有效日期：2020-09-16 至 2023-09-16 </span>
                  <span>总合同面积：278m²</span>
                  <span>合同说明：-</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="coop-content"
        v-if="!showImg"
      >
        <ul class="coop-list">
          <li
            v-for="item in list"
            :key="item.id"
            @click="toSignDetail(item)"
            class="pointer"
          >
            <div class="coop-list-head">
              <span class="text-bold">
                {{item.projectName}}
              </span>
              <p>
                <span>
                  楼宇楼层：{{item.building}}/{{item.floor }}
                </span>
                <span>
                  铺位号：{{item.berthNo}}
                </span>
              </p>
              <span
                class="coop-tip"
                :class="[item.state==0?'active': '']"
              > {{item.state==0?'生效中': '已失效'}}</span>
            </div>
            <div class="coop-list-info">
              <div class="coop-list-left">
                <span>合同号：{{item.contractCode ? item.contractCode : '-'}}</span>
                <span>合同有效日期：{{item.rentBeg ? item.rentBeg + '至' + item.rentEnd: '-'}}</span>
                <span>总合同面积：{{item.rentArea ? item.rentArea + 'm²' : '-'}}</span>
                <span>合同说明：{{item.rentRemark ? item.rentRemark : '-'}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="w-page-sty"
        v-if="!showImg"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :current-page='pageNum'
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isHasJurisdiction: '',
      loading: true,
      list: [],
      pageSize: 9,
      pageNum: 1,
      total: 0,
      overData: {},
      projectList: [],
      showImg: false,
      modulesList: []
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    })
  },
  created () {
    this.getProjectList().then(() => {
      this.getInfo()
      this.getTotalData()
      this.getModularListFunc()
    })
  },
  methods: {
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    getProjectList () {
      return this.axios.post(api.overViewProjectList)
        .then((res) => {
          this.projectList = res.data.data.map(item => {
            return item.id
          })
        })
    },
    getInfo () {
      this.axios.post(api.getCooperationBrand, {
        gbid: Number(this.$route.query.gbid),
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.list = res.data.data.dataList
            if (this.list.length === 0) {
              this.showImg = true
              this.overData = {
                projectNum: 2,
                signedArea: 899.08,
                rentYear: 57.98
              }
            } else {
              this.showImg = false
            }
            this.total = res.data.data.total
          }
        })
    },
    getTotalData () {
      this.axios.post(api.getCooperationTotal, {
        gbid: Number(this.$route.query.gbid)
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.overData = res.data.data
          }
        })
    },
    pageChange (val) {
      this.pageNum = val
      this.getInfo()
    },
    //
    toSignDetail (detail) {
      if (this.projectList.indexOf(detail.projectId) === -1) {
        this.$message({
          type: 'warning',
          message: '你当前暂无对应项目铺位的查看权限',
          duration: 3000
        })
      } else {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + detail.contractCode + '&contractId=' + detail.contractId + '&mallCode=' + detail.projectId + '&menuCode=12&tokenval=' + this.token + '&operateStep=3', '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.cooperation
  background #272930
  padding 30px 24px 50px 24px
  .content
    margin-top 30px
    color #fff
    .fr
      i
        color rgba(255, 255, 255, 0.3)
.coop-head
  text-align center
  width 740px
  margin 0 auto
  ul
    li
      float left
      width 216px
      height 103px
      background url('~@/assets/images/brandDetail/bg.png') no-repeat
      text-align center
      padding-top 35px
      margin-right 30px
      .p1
        color #ffffff
        font-size 26px
        font-weight bold
      .p2
        color #ffffff
        font-size 14px
        margin-top 20px
.coop-content
  margin-top 58px
.coop-list
  display flex
  flex-wrap wrap
  li
    margin-bottom 16px
    margin-right 16px
    width 400px
    color #e5e5e5
    font-size 14px
    line-height 24px
    background rgba(255, 255, 255, 0.1)
    box-sizing border-box
    padding 14px
    position relative
    &:nth-child(3n)
      margin-right 0
  .coop-list-head
    border-bottom 1px solid rgba(255, 255, 255, 0.2)
    padding-bottom 5px
    margin-bottom 20px
    span
      margin-right 15px
    .coop-tip
      position absolute
      top 0
      right 0
      color #ffffff
      background-color #999999
      margin 0
      padding 2px 5px
      font-size 12px
      &.active
        background-color #12A643
  .coop-list-info
    display flex
    justify-content space-between
    .coop-list-left
      span
        display block
        letter-spacing 1px
    .coop-list-right
      margin-right 5px
      span
        display block
        letter-spacing 1px
  .text-bold
    font-weight bold
    color #ffffff
.no-sign-brand
  text-align center
  margin-top 35px
  color #fff
.jurisdiction-wrap-no-data
  color #ffffff
  text-align center
  min-height 250px
  padding-top 80px
  background #272930
  img
    margin-bottom 20px
  p
    font-size 14px
    line-height 18px
.coop-head-tip
  padding 10px 20px
  color #fff
  font-size 14px
  background #3b3d46
  line-height 22px
  margin-bottom 30px
</style>
